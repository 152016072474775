import React, { useState, useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { FormControl, Grid, InputLabel, MenuItem, Select } from "@mui/material";
import ErasableTextField from "./ErasableTextField";
import countriesService from "../service/countriesService";

export default function AddressFields({
  label,
  requiredMessage,
  renderError,
  entity,
  disabled = false,
  defaultCountryProp = null,
}) {
  const { t } = useTranslation("common", { keyPrefix: "address" });

  const defaultCountry =
    defaultCountryProp || entity[label]?.state?.country_id || "";

  const [countries, setCountries] = useState([]);
  const [selectedCountryId, setSelectedCountryId] = useState(defaultCountry);
  const [states, setStates] = useState([]);
  const [countriesLoaded, setCountriesLoaded] = useState(false);
  const [statesLoaded, setStatesLoaded] = useState(false);

  const {
    register,
    formState: { errors },
  } = useFormContext();

  const renderSelectOptions = (options) =>
    options.map((option) => (
      <MenuItem value={option.id} key={option.id}>
        {option.name}
      </MenuItem>
    ));

  const getCountries = async () => {
    setCountriesLoaded(false);
    setStatesLoaded(false);
    const response = await countriesService.fetchAllCountries();
    if (response.data) {
      setCountries(response.data);
      setCountriesLoaded(true);
      if (!defaultCountry) {
        const us = response.data.find(
          (country) => country.name === "United States"
        );
        setSelectedCountryId(us?.id);
      }
    }
  };

  const getStates = async () => {
    if (selectedCountryId) {
      const response = await countriesService.fetchStatesByCountry(
        selectedCountryId
      );
      if (response.data) {
        setStates(response.data);
        setStatesLoaded(true);
      }
    }
  };

  const hasErrors = (field) =>
    errors?.[label] && errors?.[label][field] ? errors?.[label][field] : false;

  const renderErrorMessage = (field) => {
    if (errors?.[label] && errors?.[label][field]) {
      return renderError(errors?.[label][field].message);
    }
    return "";
  };

  useEffect(() => {
    (async () => {
      await getCountries();
    })();
  }, []);

  const getSelectedCountry = (e) => {
    const countryId = e.target.value;
    setSelectedCountryId(countryId);
  };

  useEffect(() => {
    if (entity.id && entity.id !== 0) {
      setSelectedCountryId(entity[label]?.country_id);
    }
  }, [entity]);

  useEffect(() => {
    (async () => {
      await getStates();
    })();
  }, [selectedCountryId]);

  return (
    <>
      <Grid item sm={12}>
        <ErasableTextField
          id={`${label}.address_line1`}
          label={t("address1")}
          fullWidth
          isRequired
          hasError={hasErrors("address_line1")}
          defaultValue={entity[label]?.address_line1 || ""}
          disabled={disabled}
        />
        {renderErrorMessage("address_line1")}
      </Grid>

      <Grid item sm={12}>
        <ErasableTextField
          id={`${label}.address_line2`}
          label={t("address2")}
          fullWidth
          isRequired={false}
          hasError={hasErrors("address_line2")}
          defaultValue={entity[label]?.address_line2 || ""}
          disabled={disabled}
        />

        {renderErrorMessage("address_line2")}
      </Grid>
      <Grid item sm={5}>
        <ErasableTextField
          id={`${label}.city`}
          label={t("city")}
          fullWidth
          isRequired
          hasError={hasErrors("city")}
          defaultValue={entity[label]?.city || ""}
          disabled={disabled}
        />

        {renderErrorMessage("city")}
      </Grid>
      <Grid item sm={3}>
        <FormControl fullWidth error={hasErrors("state_id")}>
          <InputLabel id={`${label}.state_id`}>{`${t("state")}${
            states.length > 0 ? "*" : ""
          }`}</InputLabel>
          {states.length > 0 && statesLoaded && (
            <Select
              labelId={`${label}.state_id`}
              id={`${label}.state_id`}
              label={t("state")}
              defaultValue={entity[label]?.state_id || ""}
              disabled={disabled}
              {...register(`${label}.state_id`, {
                required:
                  states.length > 0 ? requiredMessage && !disabled : false,
              })}
            >
              <MenuItem disabled value="">
                {t("state")}
              </MenuItem>
              {renderSelectOptions(states)}
            </Select>
          )}
        </FormControl>

        {renderErrorMessage(`state_id`)}
      </Grid>
      <Grid item sm={4}>
        <ErasableTextField
          id={`${label}.postal_code`}
          label={t("postalCode")}
          fullWidth
          isRequired
          hasError={hasErrors("postal_code")}
          defaultValue={entity[label]?.postal_code || ""}
          disabled={disabled}
        />

        {renderErrorMessage("postal_code")}
      </Grid>
      <Grid item sm={4}>
        <FormControl fullWidth error={hasErrors("country_id")}>
          <InputLabel id={`${label}.country_id`}>{t("country")}</InputLabel>
          {countries.length > 0 && countriesLoaded && (
            <Select
              labelId={`${label}.country_id`}
              id={`${label}.country_id`}
              label={t("country")}
              defaultValue={defaultCountry || selectedCountryId}
              disabled={disabled}
              {...register(`${label}.country_id`, {
                required: countries.length > 0 ? requiredMessage : false,
                onChange: (e) => getSelectedCountry(e),
              })}
            >
              {renderSelectOptions(countries)}
            </Select>
          )}
        </FormControl>

        {renderErrorMessage("country_id")}
      </Grid>
    </>
  );
}
