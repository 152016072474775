import {
  Box,
  Container,
  Drawer,
  FormControlLabel,
  Stack,
  Switch,
  Typography,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { font28, rightDrawerContainer, switchBtn } from "../../sharedStyles";
import { QuickBarContext } from "../../../context/QuickBarContext";
import CommunityServiceTable from "./CommunityServiceTable";
import SnackBarNotification from "../../SnackBarNotification";
import studentsService from "../../../service/studentsService";
import CommunityServiceNew from "./CommunityServiceNew";
import CommunityServiceEdit from "./CommunityServiceEdit";
import AlertDialog from "../../AlertDialog";
import communityServices from "../../../service/communityServices";
import Authorize from "../../Authorize";
import { PermissionsContext } from "../../../context/PermissionsContext";
import APP_PERMISSIONS from "../../../utils/constants/permissions";
import PERMISSION_TYPES from "../../../utils/constants/permission_types";

export default function StudentInfoCommunityService() {
  const [searchParams] = useSearchParams();
  const [services, setServices] = useState([]);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const quickBarContext = useContext(QuickBarContext);
  const [snackBarOpen, setSnackBarOpen] = useState({
    open: false,
    message: null,
  });

  const [activeService, setActiveService] = useState(null);
  const [loading, setLoading] = useState(false);
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [totalHours, setTotalHours] = useState(0);
  const [order, setOrder] = useState(searchParams.get("direction") || "asc");
  const [orderBy, setOrderBy] = useState(
    searchParams.get("sort") || "school_year"
  );

  const { hasAnyPermissionType, hasPermission } =
    useContext(PermissionsContext);

  const communityServicePermission = hasAnyPermissionType(
    APP_PERMISSIONS.STUDENT_COMMUNITY_SERVICE
  );

  const hasManagePermission = hasPermission(
    APP_PERMISSIONS.STUDENT_COMMUNITY_SERVICE,
    PERMISSION_TYPES.MANAGE
  );

  const params = useParams();

  const getAllCommunityServices = async () => {
    setLoading(true);
    const response = await studentsService.fetchStudentCommunityServices(
      params.student_slug,
      {
        params: {
          order,
          orderBy,
        },
      }
    );

    if (response.data) {
      setServices(response.data);
      setLoading(false);
    }

    if (response.total_hours) {
      setTotalHours(response.total_hours);
    }
  };

  const handleEditDrawer = (service) => {
    setDrawerOpen(true);
    setActiveService(service);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
    setActiveService(null);
  };

  const handleDeleteDialog = (service) => {
    setActiveService(service);
    setDeleteDialog(true);
  };

  const handleDeleteService = async () => {
    const response = await communityServices.deleteCommunityService({
      id: activeService.id,
    });

    if (response.data.message === "Deleted successfully") {
      setSnackBarOpen({
        open: true,
        message: "Service deleted successfully.",
      });
      setDeleteDialog(false);
      setActiveService(null);
      setDrawerOpen(false);
      getAllCommunityServices();
    }
  };

  useEffect(() => {
    if (quickBarContext.addCommunityService) {
      setDrawerOpen(true);
      quickBarContext.cleanAddCommunityService();
    }
  });

  useEffect(() => {
    getAllCommunityServices();
  }, [drawerOpen, order, orderBy]);

  return (
    <Authorize permission={communityServicePermission}>
      <Container maxWidth={false} variant="header">
        <Stack direction="row" justifyContent="space-between">
          <Typography sx={font28}>Record of Community Service</Typography>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            {
              // temporarly hidden
              false && hasManagePermission && (
                <FormControlLabel
                  control={
                    <Switch
                      sx={switchBtn}
                      checked
                      onChange={(e) => {
                        console.log(e.target.checked);
                      }}
                    />
                  }
                  label="View all years"
                />
              )
            }

            <p>Total Hours: {totalHours}</p>
          </Stack>
        </Stack>
      </Container>
      <Container>
        <CommunityServiceTable
          handleEditDrawer={handleEditDrawer}
          order={order}
          orderBy={orderBy}
          setOrder={setOrder}
          setOrderBy={setOrderBy}
          services={services}
          loading={loading}
          handleDeleteDialog={handleDeleteDialog}
          hasManagePermission={hasManagePermission}
        />
      </Container>

      <Drawer open={drawerOpen} anchor="right">
        <Box sx={rightDrawerContainer}>
          {activeService ? (
            <CommunityServiceEdit
              setDrawerOpen={setDrawerOpen}
              setSnackBarOpen={setSnackBarOpen}
              activeService={activeService}
              handleDrawerClose={handleDrawerClose}
              studentSlug={params.student_slug}
              handleDeleteDialog={handleDeleteDialog}
            />
          ) : (
            <CommunityServiceNew
              setDrawerOpen={setDrawerOpen}
              setSnackBarOpen={setSnackBarOpen}
              handleDrawerClose={handleDrawerClose}
              studentSlug={params.student_slug}
            />
          )}
        </Box>
      </Drawer>
      <SnackBarNotification
        message={snackBarOpen.message}
        open={snackBarOpen.open}
        severity={snackBarOpen.severity}
        handleClose={() => setSnackBarOpen(false)}
      />
      <AlertDialog
        isOpen={deleteDialog}
        handleClose={() => {
          setDeleteDialog(false);
          setActiveService(null);
        }}
        handleConfirm={() => handleDeleteService()}
      />
    </Authorize>
  );
}
