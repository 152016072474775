const klassesListName = {
  width: "250px",
};

const klassesListSubject = {
  width: "250px",
};

const klassesListDescription = {
  width: "300px",
};

const klassesSettingsCell = {
  color: "#808080",
  cursor: "pointer",
};

const dragHandle = {
  marginTop: "5px",
  marginRight: "10px",
};

const draggingRow = {
  backgroundColor: "#f0f0f0",

  "& > *:first-of-type": {
    width: "100%",
  },

  "& > *:not(:first-of-type) p": {
    display: "none",
  },
};

const customOrderSwitchers = {
  display: "flex",
  justifyContent: "flex-end",
  alignItems: "center",
};

export {
  klassesListName,
  klassesListSubject,
  klassesListDescription,
  klassesSettingsCell,
  draggingRow,
  customOrderSwitchers,
  dragHandle,
};
