import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Stack,
  Table,
  TableBody,
  TableContainer,
  Typography,
} from "@mui/material";
import { FormProvider, useForm } from "react-hook-form";
import {
  bgWhite,
  font20,
  formButtons,
  formContainer,
  formHeader,
  formTitle,
  mt15,
  my15,
} from "../../../sharedStyles";
import ErasableTextField from "../../../ErasableTextField";
import CanvasCoursesKlassRow from "./CanvasCoursesKlassRow";
import GenericSubmitButton from "../../../GenericSubmitButton";
import canvasService from "../../../../service/canvasService";
import { SnackbarContext } from "../../../../context/SnackbarContext";
import klassesService from "../../../../service/klassesService";

export default function CanvasCoursesForm({
  handleCloseDrawer,
  activeCourse,
  setDeleteDialogOpen,
  courses,
}) {
  const [klasses, setKlasses] = useState([]);
  const [selectedAeCourse, setSelectedAeCourse] = useState(
    activeCourse?.ae_connect_course_id || null
  );
  const [selectedKlasses, setSelectedKlasses] = useState([]);
  const snackbarContext = useContext(SnackbarContext);
  const methods = useForm();

  const getKlasses = async () => {
    const response = await klassesService.fetchYearKlasses({
      params: {
        course_id: selectedAeCourse,
      },
    });
    if (response.data) {
      setKlasses(response.data);
      setSelectedKlasses(
        activeCourse
          ? response.data?.filter(
              (k) => k.canvas_course_id === activeCourse?.id
            )
          : []
      );
    }
  };

  const onSubmit = async (data) => {
    if (activeCourse) {
      const response = await canvasService.updateCanvasCourse({
        ...data,
        id: activeCourse.id,
        klasses: selectedKlasses,
      });

      if (response) {
        snackbarContext.setSnackbar({
          message: "Course updated.",
          severity: "success",
          open: true,
        });

        handleCloseDrawer();
      }
    } else {
      const response = await canvasService.createCanvasCourse({
        ...data,
        klasses: selectedKlasses,
      });

      if (response) {
        snackbarContext.setSnackbar({
          message: "Course created.",
          severity: "success",
          open: true,
        });

        handleCloseDrawer();
      }
    }
  };

  useEffect(() => {
    if (selectedAeCourse) {
      getKlasses();
    }
  }, [selectedAeCourse]);

  useEffect(() => {
    methods.setValue("canvas_term", "year");
  }, []);

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <Stack direction="row" sx={formHeader}>
          <Typography sx={formTitle}>
            {activeCourse ? "Edit" : "Create"} Canvas Course
          </Typography>
          <Stack direction="row">
            <Button sx={formButtons} onClick={() => handleCloseDrawer()}>
              Cancel
            </Button>
            {activeCourse && (
              <Button
                sx={formButtons}
                onClick={() => setDeleteDialogOpen(true)}
              >
                Delete
              </Button>
            )}
            <GenericSubmitButton text="Save" submittingText="Saving..." />
          </Stack>
        </Stack>

        <Box sx={formContainer}>
          <Grid
            container
            spacing={2}
            sx={{ borderBottom: "1px solid #BFC7CA", mb: "30px", pb: "30px" }}
          >
            <Grid item xs={8}>
              <RadioGroup row aria-labelledby="canvas_term" name="canvas_term">
                <FormControlLabel
                  value="year"
                  control={<Radio />}
                  label="Year Term"
                  checked
                  disabled
                />
              </RadioGroup>
            </Grid>
          </Grid>

          <Grid
            container
            spacing={2}
            sx={{ borderBottom: "1px solid #BFC7CA", pb: "30px" }}
          >
            <Grid item xs={8}>
              <ErasableTextField
                id="name"
                label="Name*"
                fullWidth
                isRequired
                type="text"
                defaultValue={activeCourse?.name || null}
              />
            </Grid>

            <Grid item xs={4}>
              <ErasableTextField
                id="sis_id"
                label="SIS ID*"
                fullWidth
                isRequired
                type="text"
                defaultValue={activeCourse?.canvas_sis_id || null}
              />
            </Grid>
          </Grid>

          <Box sx={mt15}>
            <Typography sx={font20}>Choose an AE-Connect course</Typography>
            <Box sx={{ borderBottom: "1px solid #BFC7CA", py: "15px" }}>
              <FormControl sx={my15} fullWidth>
                <InputLabel sx={bgWhite} id="ae_connect_course">
                  AE-Connect Course
                </InputLabel>
                <Select
                  labelId="ae_connect_course"
                  id="ae_connect_course"
                  defaultValue={activeCourse?.ae_connect_course_id || null}
                  onChange={(e) => {
                    setSelectedAeCourse(e.target.value);
                  }}
                >
                  {courses.map((course) => (
                    <MenuItem value={course.id}>{course.name}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>

            {klasses.length > 0 && (
              <Box sx={mt15}>
                <Typography sx={font20}>Choose one or more classes</Typography>
                <TableContainer>
                  <Table size="medium">
                    <TableBody>
                      {klasses.map((klass) => (
                        <CanvasCoursesKlassRow
                          key={klass.id}
                          klass={klass}
                          setSelectedKlasses={setSelectedKlasses}
                          activeCourse={activeCourse}
                        />
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            )}
          </Box>
        </Box>
      </form>
    </FormProvider>
  );
}
