import React, { useState } from "react";
import { useForm } from "react-hook-form";
import communityServices from "../../../service/communityServices";
import CommunityServiceForm from "./CommunityServiceForm";

export default function CommunityServiceEdit({
  setDrawerOpen,
  setSnackBarOpen,
  activeService,
  handleDrawerClose,
  studentSlug,
  handleDeleteDialog,
}) {
  const [hasErrors, setErrors] = useState(false);
  const methods = useForm();

  const onSubmit = async (data) => {
    // eslint-disable-next-line no-param-reassign
    data.date = data.date ? new Date(data.date).toISOString() : null;

    if (!hasErrors) {
      const response = await communityServices.updateCommunityService({
        ...data,
        id: activeService.id,
        student_slug: studentSlug,
      });

      if (response.data) {
        setSnackBarOpen({
          open: true,
          message: "Service updated successfully.",
        });
        handleDrawerClose();
      }
    }
  };

  return (
    <CommunityServiceForm
      setDrawerOpen={setDrawerOpen}
      setSnackBarOpen={setSnackBarOpen}
      activeService={activeService}
      handleDrawerClose={handleDrawerClose}
      studentSlug={studentSlug}
      methods={methods}
      onSubmit={onSubmit}
      setErrors={setErrors}
      hasErrors={hasErrors}
      handleDeleteDialog={handleDeleteDialog}
    />
  );
}
