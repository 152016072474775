import React, { useState, useEffect } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  Typography,
  TableBody,
  CircularProgress,
} from "@mui/material";
import { DragHandle } from "@mui/icons-material";
import klassesService from "../../service/klassesService";
import { mr10, py30, mr30, loader } from "../sharedStyles";
import {
  subjectsListTable,
  subjectsListCell,
  subjectsListCellName,
} from "../../views/MySubjects.styles";
import {
  klassesListName,
  klassesListSubject,
  klassesListDescription,
  draggingRow,
  dragHandle,
} from "../../views/MyKlasses.styles";

export default function MyKlassesDroppable({ updateKlassesOrder }) {
  const params = useParams();
  const [searchParams] = useSearchParams();
  const [klasses, setKlasses] = useState();
  const schoolId = params.school_id;
  const termId = searchParams.get("term");

  const getKlasses = async () => {
    const response = await klassesService.fetchAllKlasses({
      params: {
        orderBy: "custom",
        school_id: schoolId,
        term_id: termId,
        roster_only: true,
        prek: false,
      },
    });

    setKlasses(response.data.klasses);
  };

  const handleDragEnd = (result) => {
    if (!result.destination) return;

    // There is a weird behavior on Safari when dragging the table row causes buttons to be unclickable.
    // This works around the issue by resetting the scrolling behavior and fixing the issue.
    window.scrollTo(0, window.scrollY);

    const items = Array.from(klasses);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setKlasses(items);
    updateKlassesOrder(items.map((item) => item.id));
  };

  useEffect(() => {
    getKlasses();
  }, [termId]);

  if (!klasses) return <CircularProgress sx={loader} size={100} />;

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <TableContainer sx={py30}>
        <Table sx={subjectsListTable}>
          <TableHead>
            <TableRow>
              <TableCell>
                <Typography sx={klassesListName}>NAME</Typography>
              </TableCell>
              <TableCell>
                <Typography sx={mr10}>ABBREVIATION</Typography>
              </TableCell>
              <TableCell>
                <Typography sx={klassesListSubject}>
                  SUBJECT / COURSE
                </Typography>
              </TableCell>
              <TableCell>
                <Typography sx={klassesListDescription}>DESCRIPTION</Typography>
              </TableCell>
              <TableCell>
                <Typography>ASSIGNMENTS</Typography>
              </TableCell>
              <TableCell>
                <Typography>STUDENTS</Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <Droppable droppableId="table-body" direction="vertical">
            {(droppableProvided) => (
              <TableBody
                {...droppableProvided.droppableProps}
                ref={droppableProvided.innerRef}
              >
                {klasses.map((klass, index) => (
                  <Draggable
                    key={klass.id}
                    draggableId={String(klass.id)}
                    index={index}
                    axis="y"
                  >
                    {(provided, snapshot) => (
                      <TableRow
                        hover
                        key={klass.id}
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={{
                          ...provided.draggableProps.style,
                          // Force vertical-only movement
                          transform: provided.draggableProps.style?.transform
                            ? provided.draggableProps.style.transform.replace(
                                /\(.+,/,
                                "(0,"
                              )
                            : "",
                        }}
                        sx={snapshot.isDragging ? draggingRow : {}}
                      >
                        <TableCell sx={subjectsListCellName}>
                          <Typography>
                            <DragHandle sx={dragHandle} />
                            <span>{klass.name}</span>
                          </Typography>
                        </TableCell>
                        <TableCell sx={subjectsListCell()}>
                          <Typography>
                            <span>{klass.abbreviation}</span>
                          </Typography>
                        </TableCell>
                        <TableCell sx={subjectsListCell()}>
                          <Typography>{klass.subject.name}</Typography>
                        </TableCell>
                        <TableCell sx={subjectsListCell()}>
                          <Typography>{klass.description}</Typography>
                        </TableCell>
                        <TableCell align="center" sx={subjectsListCell(true)}>
                          <Typography sx={mr30}>
                            {klass.assignments_count}
                          </Typography>
                        </TableCell>
                        <TableCell align="center" sx={subjectsListCell(true)}>
                          <Typography sx={mr30}>
                            {klass.students_count}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    )}
                  </Draggable>
                ))}
                {droppableProvided.placeholder}
              </TableBody>
            )}
          </Droppable>
        </Table>
      </TableContainer>
    </DragDropContext>
  );
}
