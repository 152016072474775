import React from "react";
import { FormProvider, useForm } from "react-hook-form";
import ErasableTextField from "../../../ErasableTextField";
import AutoSave from "../../../AutoSave";
import schoolsService from "../../../../service/schoolsService";

export default function CanvasSettingsAbbreviation({ currentSchool }) {
  const methods = useForm();

  return (
    <FormProvider {...methods}>
      <form>
        <ErasableTextField
          id="schoolAbbreviation"
          label="School Abbreviation"
          type="text"
          defaultValue={currentSchool?.abbreviation}
          fullWidth
        />
      </form>

      {methods.formState.isDirty &&
        Object.values(methods.formState.errors).length === 0 && (
          <AutoSave
            saveMethod={schoolsService.updateSchoolAbbreviation}
            params={[currentSchool.id]}
            successMessage="Saved successfully"
            progressMessage="Saving..."
          />
        )}
    </FormProvider>
  );
}
