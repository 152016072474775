import React, { useState, useEffect, useRef, useContext } from "react";
import { useSearchParams } from "react-router-dom";
import { Stack, Button } from "@mui/material";
import klassesService from "../../service/klassesService";
import {
  filterByKlassBtn,
  filterByKlassBtnActive,
} from "./SubjectFilterByClass.styles";
import { NavigationContext } from "../../context/NavigationContext";

export default function SubjectFilterByClass({
  subjectId,
  schoolId,
  handleRosterOnly,
}) {
  const scrollRef = useRef();
  const { navigateTo } = useContext(NavigationContext);
  const [searchParams, setSearchParams] = useSearchParams();
  const [klasses, setKlasses] = useState([]);
  const termId = searchParams.get("term") || null;

  const klassesParams = searchParams.get("classes");

  const getKlasses = async () => {
    const response = await klassesService.fetchAllKlasses({
      params: {
        prek: false,
        school_id: schoolId,
        subject_id: subjectId,
        term_id: termId,
        orderBy: "custom",
        roster_only: true,
      },
    });
    if (response.data) {
      const returnedKlasses = response.data.klasses.map((k, i) => ({
        index: i,
        name: k.name,
        id: k.id,
        isActive: false,
        abbreviation: k.abbreviation,
        roster_only: k.roster_only,
      }));

      if (klassesParams) {
        const filteredKlasses = [...returnedKlasses];
        const formattedParams = klassesParams.split(",").map((p) => Number(p));

        filteredKlasses
          .filter((k) => formattedParams.includes(k.id))
          // eslint-disable-next-line no-return-assign
          .map((f) => (filteredKlasses[f.index].isActive = true));

        setKlasses(filteredKlasses);
      } else {
        setKlasses(returnedKlasses);
      }
    }
  };

  const formattedParams = () => {
    let paramsUrl = "?";
    const klassesIds = searchParams.get("classes");
    if (klassesIds) {
      paramsUrl = paramsUrl.concat(`&classes=${klassesIds}`);
    }

    if (termId) {
      paramsUrl = paramsUrl.concat(`&term=${termId}`);
    }
    return paramsUrl;
  };

  const handleClick = (klassId) => {
    if (klassId !== null) {
      const filteredKlasses = [...klasses];
      const filteredKlass = filteredKlasses.filter((k) => k.id === klassId)[0];
      filteredKlass.isActive = !filteredKlass.isActive;
      setKlasses(filteredKlasses);

      const activeKlasses = filteredKlasses.filter((klass) => klass.isActive);
      const allFilteredKlassesAreRosterOnly = activeKlasses.every(
        (klass) => klass.roster_only === true
      );

      const urlParams = filteredKlasses
        .filter((k) => k.isActive === true)
        .map((f) => f.id);
      searchParams.set("classes", urlParams);
      setSearchParams(searchParams);

      if (allFilteredKlassesAreRosterOnly) {
        navigateTo(
          `/school/${schoolId}/subjects/${subjectId}/students${formattedParams()}`
        );
      }
    } else {
      setKlasses(
        klasses.map((k) => ({
          name: k.name,
          abbreviation: k.abbreviation,
          id: k.id,
          isActive: false,
          roster_only: k.roster_only,
        }))
      );
      searchParams.delete("classes");
      setSearchParams(searchParams);
    }
  };

  useEffect(() => {
    getKlasses();
  }, [subjectId, klassesParams, termId]);

  useEffect(() => {
    handleRosterOnly(klasses);
  }, [klasses]);

  useEffect(() => {
    const el = scrollRef.current;
    if (el) {
      const onWheel = (e) => {
        if (e.deltaY === 0) return;
        e.preventDefault();
        el.scrollTo({
          left: el.scrollLeft + e.deltaY,
        });
      };
      el.addEventListener("wheel", onWheel);
      return () => el.removeEventListener("wheel", onWheel);
    }

    return () => false;
  }, [klasses.length]);

  if (klasses.length === 0) return <div />;

  return (
    <Stack
      direction="row"
      sx={{
        overflowX: "hidden",
        width: "75%",
        textAlign: "right",
        whiteSpace: "nowrap",
        display: "block",
      }}
      ref={scrollRef}
    >
      <Button onClick={() => handleClick(null)} sx={filterByKlassBtn}>
        All
      </Button>
      {klasses.length > 0 &&
        klasses.map((klass) => (
          <Button
            onClick={() => handleClick(klass.id)}
            sx={
              klass.isActive || !klassesParams
                ? filterByKlassBtnActive
                : filterByKlassBtn
            }
            key={klass.id}
          >
            {klass.abbreviation}
          </Button>
        ))}
    </Stack>
  );
}
