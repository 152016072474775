import React, { useEffect, useState } from "react";
import { Box, CircularProgress, Grid, Link, Typography } from "@mui/material";
import {
  Link as RouterLink,
  Outlet,
  useLocation,
  useOutletContext,
  useParams,
} from "react-router-dom";
import {
  guardianKidsGrid,
  guardianKidLink,
  guardianKidActiveBox,
  guardianKidBox,
  guardianInactiveKidLink,
  guardianKidEnrollmentLink,
} from "./Guardian.styles";
import { fetchGuardianKids } from "../service/guardiansService";
import { loader, pl20, pt20 } from "../components/sharedStyles";

export default function Guardian() {
  const params = useParams();
  const guardianId = params.id;
  let kidId = params.kid_id;
  const [kids, setKids] = useState([]);
  const [setKidSchool, kidInfo] = useOutletContext();
  const location = useLocation();
  const isKidView = location.pathname.includes("students");
  const setDisplay = !isKidView && "none";
  const [loading, setLoading] = useState(false);
  const isEnrollmentChecklistPath = location.pathname.endsWith(
    "/home/enrollments/checklist"
  );
  const isHomePagePath = location.pathname.endsWith("/home");

  useEffect(() => {
    (async () => {
      if (guardianId) {
        try {
          setLoading(true);
          const response = await fetchGuardianKids(guardianId);

          if (response.data) {
            setLoading(false);
            setKids(response.data);
            kidId ||= response.data[0].id;
            const kidSchool = response.data.find(
              (kid) => kid.id === parseInt(kidId, 10)
            ).school;
            setKidSchool(kidSchool);
          }
        } catch (e) {
          if (e.response && e.response.data.message === "Guardian mismatched") {
            window.location.replace("/login");
          }
        }
      }
    })();
  }, [guardianId, kidId]);

  if (loading) {
    return <CircularProgress sx={loader} size={100} />;
  }

  return kids.length > 0 ? (
    <>
      <Grid container sx={guardianKidsGrid} style={{ display: setDisplay }}>
        {kids.map((kid) => (
          <React.Fragment key={kid.id}>
            {kid?.has_enrollment ? (
              <>
                <Box
                  mx={2}
                  my={1}
                  sx={
                    kid.id === parseInt(kidId, 10) && isHomePagePath
                      ? guardianKidActiveBox
                      : guardianKidBox
                  }
                >
                  <Link
                    underline="none"
                    component={RouterLink}
                    sx={guardianKidLink}
                    to={`/guardian/${guardianId}/students/${kid.id}/home`}
                  >
                    {kid.first_name} {kid.last_name}
                  </Link>
                </Box>

                {kid?.has_process &&
                  kid?.admissions_active &&
                  kid.has_any_process_active && (
                    <Box
                      mx={2}
                      my={1}
                      sx={
                        kid.id === parseInt(kidId, 10) &&
                        isEnrollmentChecklistPath
                          ? guardianKidActiveBox
                          : guardianKidBox
                      }
                    >
                      <Link
                        underline="none"
                        component={RouterLink}
                        sx={guardianKidEnrollmentLink}
                        to={`/guardian/${guardianId}/students/${kid.id}/home/enrollments/checklist`}
                      >
                        Enrollment Checklist
                      </Link>
                    </Box>
                  )}
              </>
            ) : (
              <>
                <Box mx={2} my={1} sx={guardianInactiveKidLink}>
                  {kid.first_name} {kid.last_name}
                </Box>
                {kid?.has_process && (
                  <Box
                    mx={2}
                    my={1}
                    sx={
                      kid.id === parseInt(kidId, 10) &&
                      isEnrollmentChecklistPath
                        ? guardianKidActiveBox
                        : guardianKidBox
                    }
                  >
                    <Link
                      underline="none"
                      component={RouterLink}
                      sx={guardianKidEnrollmentLink}
                      to={`/guardian/${guardianId}/students/${kid.id}/home/enrollments/checklist`}
                    >
                      Enrollment Checklist
                    </Link>
                  </Box>
                )}
              </>
            )}
          </React.Fragment>
        ))}
      </Grid>

      <Outlet context={{ kidInfo }} />
    </>
  ) : (
    <Typography sx={[pl20, pt20]}>
      {`You don't have access to your kids portal.`}
    </Typography>
  );
}
