import {
  ButtonBase,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import LockIcon from "@mui/icons-material/Lock";
import { tableStyles } from "./StudentDetails.style";
import admissionProcessService from "../../../../service/admissionProcessService";
import StageElementType from "../../Setup/AdmissionProcessBuilder/Models/constants";
import { formatDateTime } from "../../../../utils/constants/utils";
import admissionFormService from "../../../../service/admissionFormService";

export default function Forms() {
  const [searchParams] = useSearchParams();
  const params = useParams();
  const [elementInstances, setElementInstances] = useState([]);
  const navigate = useNavigate();

  const getElementInstances = async () => {
    await admissionProcessService
      .elementInstances(
        searchParams.get("student_id"),
        StageElementType.FORM,
        searchParams.get("school_year")
      )
      .then((response) => {
        setElementInstances(response.data);
      });
  };

  useEffect(() => {
    getElementInstances();
  }, [searchParams.get("school_year")]);

  const formattedParams = () => {
    let paramsUrl = "?";
    const schoolYearId = searchParams.get("school_year");
    const studentId = searchParams.get("student_id");
    const processId = searchParams.get("process_id");

    if (schoolYearId) {
      paramsUrl = paramsUrl.concat(`&school_year=${schoolYearId}`);
    }

    if (studentId) {
      paramsUrl = paramsUrl.concat(`&student_id=${studentId}`);
    }

    if (processId) {
      paramsUrl = paramsUrl.concat(`&process_id=${processId}`);
    }

    return paramsUrl;
  };

  const navigateTopDataView = (elementInstanceId, formId) => {
    let paramsUrl = formattedParams();
    paramsUrl = paramsUrl.concat(`&element_instance_id=${elementInstanceId}`);
    paramsUrl = paramsUrl.concat(`&form_id=${formId}`);
    navigate(`/school/${params.school_id}/data-view${paramsUrl}`);
  };

  const handleClick = (elementInstanceId) => () => {
    admissionFormService.toggleFormLocked(elementInstanceId).then(() => {
      getElementInstances();
    });
  };

  return (
    <TableContainer>
      <Table sx={tableStyles}>
        <TableHead>
          <TableRow>
            <TableCell>NAME</TableCell>
            <TableCell>DUE</TableCell>
            <TableCell>COMPLETED</TableCell>
            <TableCell>STATUS</TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {elementInstances.map((elementInstance) => (
            <TableRow>
              <TableCell>
                <ButtonBase
                  onClick={() =>
                    navigateTopDataView(
                      elementInstance.id,
                      elementInstance.properties.id
                    )
                  }
                >
                  {elementInstance.stage_element.name}
                </ButtonBase>
              </TableCell>
              <TableCell>
                {elementInstance.deadline
                  ? formatDateTime(elementInstance.deadline)
                  : "-"}
              </TableCell>
              <TableCell>
                {elementInstance.date_completed
                  ? formatDateTime(elementInstance.date_completed)
                  : "-"}
              </TableCell>
              <TableCell>To Review</TableCell>
              <TableCell>
                <IconButton
                  aria-label="more"
                  id="long-button"
                  aria-haspopup="true"
                  onClick={handleClick(elementInstance.id)}
                >
                  {elementInstance.status === "completed" ? (
                    <Tooltip title="Click to unlock" placement="top" arrow>
                      <LockIcon />
                    </Tooltip>
                  ) : (
                    <Tooltip title="Click to lock" placement="top" arrow>
                      <LockOpenIcon />
                    </Tooltip>
                  )}
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
