import React from "react";
import { Grid } from "@mui/material";

export default function FieldRenderer({
  field,
  getDbField,
  form,
  section,
  guardianView,
  dataView,
  formattedView,
  formRenderMap,
  parentSectionIndex,
  emergencyContactSectionIndex,
  key,
}) {
  if (!field.isVisible) {
    return null;
  }

  const Component = formRenderMap[field.type];
  if (!Component) {
    return null;
  }

  const dbField = getDbField(
    field.label,
    section.isParentInfo,
    section.isEmergencyContactInfo
  );
  let existingData = null;

  const flattenObject = (obj) => {
    const flattened = {};
    if (!obj) {
      return flattened;
    }

    Object.keys(obj).forEach((_key) => {
      // eslint-disable-next-line no-underscore-dangle
      let value = obj[_key];

      if (_key === "country" && typeof value === "object" && value !== null) {
        value = value.name;
      }
      if (_key === "state" && typeof value === "object" && value !== null) {
        value = value.name;
      }

      if (
        typeof value === "object" &&
        value !== null &&
        !Array.isArray(value)
      ) {
        Object.assign(flattened, flattenObject(value));
      } else {
        // eslint-disable-next-line no-underscore-dangle
        flattened[_key] = value;
      }
    });

    return flattened;
  };

  if (dbField && !section.isParentInfo && !section.isEmergencyContactInfo) {
    existingData = form.existingStudentData?.[dbField] || null;
  } else if (dbField && section.isParentInfo) {
    const existingParentData = flattenObject(
      form.existingParentData[parentSectionIndex]
    );

    existingData = existingParentData?.[dbField] || null;
  } else if (dbField && section.isEmergencyContactInfo) {
    const existingEmergencyContactData = flattenObject(
      form.existingEmergencyContactData[emergencyContactSectionIndex]
    );
    existingData = existingEmergencyContactData?.[dbField] || null;
  }

  return (
    <Grid
      key={key}
      item
      md={field.w}
      sx={{
        position: "relative",
        height: "fit-content",
      }}
    >
      <Component
        field={{
          ...field,
          existing_data: guardianView ? existingData : null,
        }}
        properties={{
          ...field.properties,
          shrink: formattedView,
        }}
        dataView={dataView}
      />
    </Grid>
  );
}
