import React from "react";
import { Divider, Grid, Typography } from "@mui/material";
import FieldLayout from "./FieldLayout";

export default function SectionLayout({ section }) {
  return (
    <Grid container mt={2} key={`${section.id}-${section.sectionId}`}>
      <Grid item xs={12}>
        <Typography variant="h6" fontWeight={600} gutterBottom>
          {section.name}
        </Typography>
        <Divider sx={{ mb: 2 }} />
      </Grid>

      {section.fields.map((field) => (
        <FieldLayout field={field} />
      ))}
    </Grid>
  );
}
