import React, { useState } from "react";
import { useSearchParams } from "react-router-dom";
import {
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  CircularProgress,
  Container,
  IconButton,
  Menu,
  MenuItem,
  Stack,
} from "@mui/material";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import { MoreVert } from "@mui/icons-material";
import { py10, loader } from "../../sharedStyles";
import { cSTableCell, cSTableHead } from "./CommunityServiceTable.styles";
import dateTimeFormats from "../../../utils/constants/dateTimeFormats";
import moment from "../../../utils/constants/momentConfig";

export default function CommunityServiceTable({
  handleEditDrawer,
  services,
  loading,
  handleDeleteDialog,
  order,
  orderBy,
  setOrder,
  setOrderBy,
  hasManagePermission,
}) {
  const [searchParams, setSearchParams] = useSearchParams();
  const [menuAnchors, setMenuAnchors] = useState({});

  const renderSortChevron = (label) => {
    const noColumnSelected = searchParams.get("sort") === null;

    if ((noColumnSelected && label === "name") || label === orderBy) {
      return order === "asc" ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />;
    }
    return <ArrowDropUpIcon sx={{ visibility: "hidden" }} />;
  };

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    const direction = isAsc ? "desc" : "asc";
    setOrder(direction);
    setOrderBy(property);

    searchParams.set("sort", property);
    searchParams.set("direction", direction);
    setSearchParams(searchParams);
  };

  const handleMenuClick = (event, id) => {
    setMenuAnchors((prev) => ({
      ...prev,
      [id]: event.currentTarget,
    }));
  };

  const handleMenuClose = (id) => {
    setMenuAnchors((prev) => ({
      ...prev,
      [id]: null,
    }));
  };

  if (loading) {
    return <CircularProgress sx={loader} size={100} />;
  }

  return (
    <Container maxWidth={false} variant="header">
      <Container maxWidth="xl">
        {services.length > 0 ? (
          <TableContainer sx={py10}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell
                    sx={cSTableHead(orderBy === "school_year")}
                    onClick={() => handleRequestSort("school_year")}
                  >
                    <Typography sx={{ textTransform: "uppercase" }}>
                      Year {renderSortChevron("school_year")}
                    </Typography>
                  </TableCell>
                  <TableCell
                    sx={cSTableHead(orderBy === "date")}
                    onClick={() => handleRequestSort("date")}
                  >
                    <Typography sx={{ textTransform: "uppercase", ml: "10%" }}>
                      Date {renderSortChevron("date")}
                    </Typography>
                  </TableCell>
                  <TableCell
                    sx={cSTableHead(orderBy === "hours")}
                    onClick={() => handleRequestSort("hours")}
                  >
                    <Typography sx={{ textTransform: "uppercase", ml: "10%" }}>
                      Hours {renderSortChevron("hours")}
                    </Typography>
                  </TableCell>
                  <TableCell
                    sx={cSTableHead(orderBy === "description")}
                    onClick={() => handleRequestSort("description")}
                  >
                    <Typography sx={{ textTransform: "uppercase" }}>
                      Description {renderSortChevron("description")}
                    </Typography>
                  </TableCell>
                  <TableCell
                    sx={cSTableHead(orderBy === "verified_by")}
                    onClick={() => handleRequestSort("verified_by")}
                  >
                    <Typography sx={{ textTransform: "uppercase" }}>
                      Verified By {renderSortChevron("verified_by")}
                    </Typography>
                  </TableCell>
                  <TableCell
                    sx={cSTableHead(orderBy === "supervisor")}
                    onClick={() => handleRequestSort("supervisor")}
                  >
                    <Typography sx={{ textTransform: "uppercase" }}>
                      Supervisor {renderSortChevron("supervisor")}
                    </Typography>
                  </TableCell>
                  <TableCell
                    sx={cSTableHead(orderBy === "requirement")}
                    onClick={() => handleRequestSort("requirement")}
                  >
                    <Typography sx={{ textTransform: "uppercase" }}>
                      Requirement {renderSortChevron("requirement")}
                    </Typography>
                  </TableCell>
                  <TableCell
                    sx={cSTableHead(orderBy === "service_type")}
                    onClick={() => handleRequestSort("service_type")}
                  >
                    <Typography sx={{ textTransform: "uppercase" }}>
                      Service Type {renderSortChevron("service_type")}
                    </Typography>
                  </TableCell>
                  <TableCell sx={cSTableHead()} />
                </TableRow>
              </TableHead>
              <TableBody>
                {services.map((service) => (
                  <TableRow key={service.id}>
                    <TableCell sx={cSTableCell}>
                      <Typography>{service.school_year}</Typography>
                    </TableCell>
                    <TableCell sx={cSTableCell}>
                      <Typography sx={{ ml: "10%" }}>
                        {service.date &&
                          moment(service.date).format(dateTimeFormats.ll)}
                      </Typography>
                    </TableCell>
                    <TableCell sx={cSTableCell}>
                      <Typography sx={{ ml: "10%" }}>
                        {service.hours}
                      </Typography>
                    </TableCell>
                    <TableCell sx={[cSTableCell, { width: "300px" }]}>
                      <Typography>{service.description || "-"}</Typography>
                    </TableCell>
                    <TableCell sx={cSTableCell}>
                      <Typography>{service.verified_by || "-"}</Typography>
                    </TableCell>
                    <TableCell sx={cSTableCell}>
                      <Typography>{service.supervisor || "-"}</Typography>
                    </TableCell>
                    <TableCell sx={cSTableCell}>
                      <Typography>{service.requirement || "-"}</Typography>
                    </TableCell>
                    <TableCell sx={cSTableCell}>
                      <Typography>{service.service_type || "-"}</Typography>
                    </TableCell>
                    <TableCell sx={cSTableCell}>
                      <Stack
                        direction="row"
                        justifyContent="flex-end"
                        alignItems="center"
                      >
                        {service.notes && <DescriptionOutlinedIcon />}
                        {hasManagePermission && (
                          <IconButton
                            onClick={(e) => handleMenuClick(e, service.id)}
                          >
                            <MoreVert />
                          </IconButton>
                        )}
                      </Stack>

                      {hasManagePermission && (
                        <Menu
                          id={`long-menu-${service.id}`}
                          anchorEl={menuAnchors[service.id]}
                          open={Boolean(menuAnchors[service.id])}
                          onClose={() => handleMenuClose(service.id)}
                        >
                          <MenuItem
                            autoFocus={false}
                            onClick={() => {
                              handleEditDrawer(service);
                              handleMenuClose(service.id);
                            }}
                          >
                            Edit
                          </MenuItem>
                          <MenuItem onClick={() => handleDeleteDialog(service)}>
                            Delete
                          </MenuItem>
                        </Menu>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <Typography align="center">
            No community services for this student.
          </Typography>
        )}
      </Container>
    </Container>
  );
}
