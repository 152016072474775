import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  Typography,
} from "@mui/material";
import { alignCenter, bgWhite, font20, switchBtn } from "../../../sharedStyles";
import canvasService from "../../../../service/canvasService";
import { SnackbarContext } from "../../../../context/SnackbarContext";

export default function CanvasSettingsExportMode({ integrationInfo }) {
  const [batchMode, setBatchMode] = useState(
    integrationInfo?.canvas_full_batch_mode
  );

  // eslint-disable-next-line no-unused-vars
  const [batchModeTermId, setBatchModeTermId] = useState(
    integrationInfo?.canvas_batch_mode_term_id
  );
  const [diffingMode, setDiffingMode] = useState(
    integrationInfo?.canvas_diffing_mode
  );
  const [diffingModeRemaster, setDiffingModeRemaster] = useState(
    integrationInfo?.canvas_diffing_mode_remaster
  );
  const snackbarContext = useContext(SnackbarContext);

  const [canvasTerms, setCanvasTerms] = useState([]);

  const updateSeetings = async (params) => {
    const response = await canvasService.updateSettings(params);

    if (response) {
      snackbarContext.setSnackbar({
        open: true,
        message: "Updated successfully",
        severity: "success",
      });
    }
  };

  const getCanvasTerms = async () => {
    const response = await canvasService.getCanvasTerms();

    if (response.data) {
      setCanvasTerms(response.data);
    }
  };

  useEffect(() => {
    getCanvasTerms();
  }, []);

  return (
    <Box>
      <Grid container item xs={12} mt={2} sx={alignCenter}>
        {canvasTerms.length > 0 && (
          <>
            <Grid item xs={4}>
              <Typography sx={font20}>Full Batch Mode</Typography>
            </Grid>
            <Grid item xs={8}>
              <FormControlLabel
                sx={{ pt: "10px" }}
                control={
                  <Switch
                    sx={switchBtn}
                    checked={batchMode}
                    onChange={(e) => {
                      setBatchMode(e.target.checked);
                      setDiffingMode(false);
                      setDiffingModeRemaster(false);

                      if (batchMode === true && e.target.checked === false) {
                        updateSeetings({
                          canvas_full_batch_mode: false,
                          canvas_batch_mode_term_id: "",
                          canvas_diffing_mode: false,
                          canvas_diffing_mode_remaster: false,
                        });
                      }
                    }}
                  />
                }
              />
            </Grid>
          </>
        )}
        {batchMode && canvasTerms.length > 0 && (
          <Grid item xs={8} my={3}>
            <FormControl fullWidth>
              <InputLabel sx={bgWhite} id="canvas_batch_mode_term_id">
                Term
              </InputLabel>
              <Select
                labelId="canvas_batch_mode_term_id"
                id="canvas_batch_mode_term_id"
                defaultValue={integrationInfo?.canvas_batch_mode_term_id}
                onChange={(e) => {
                  setBatchModeTermId(e.target.value);
                  updateSeetings({
                    canvas_full_batch_mode: true,
                    canvas_batch_mode_term_id: e.target.value,
                    canvas_diffing_mode: false,
                    canvas_diffing_mode_remaster: false,
                  });
                }}
              >
                {canvasTerms.map((term) => (
                  <MenuItem value={term.id}>{term.name}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        )}
      </Grid>
      <Grid container item xs={12} mt={2}>
        <Grid item xs={4}>
          <Typography sx={font20}>Diffing Mode</Typography>
        </Grid>
        <Grid item xs={8}>
          <FormControlLabel
            sx={{ pt: "10px" }}
            control={
              <Switch
                sx={switchBtn}
                checked={diffingMode}
                onChange={(e) => {
                  setDiffingMode(e.target.checked);

                  if (e.target.checked === true) {
                    setBatchMode(false);
                  }

                  if (
                    e.target.checked === false &&
                    diffingModeRemaster === true
                  ) {
                    setDiffingModeRemaster(false);
                  }

                  updateSeetings({
                    canvas_full_batch_mode: false,
                    canvas_batch_mode_term_id: "",
                    canvas_diffing_mode: e.target.checked,
                    canvas_diffing_mode_remaster:
                      e.target.checked === false ? false : diffingModeRemaster,
                  });
                }}
              />
            }
          />
        </Grid>
      </Grid>
      <Grid container item xs={12} mt={2}>
        <Grid item xs={4}>
          <Typography sx={font20}>Diffing Mode with Remaster</Typography>
        </Grid>
        <Grid item xs={8}>
          <FormControlLabel
            control={
              <Switch
                sx={switchBtn}
                checked={diffingModeRemaster}
                onChange={(e) => {
                  setDiffingModeRemaster(e.target.checked);

                  if (e.target.checked === true) {
                    setBatchMode(false);
                    setDiffingMode(true);
                  }

                  updateSeetings({
                    canvas_full_batch_mode: false,
                    canvas_batch_mode_term_id: "",
                    canvas_diffing_mode:
                      e.target.checked === true ? true : diffingMode,
                    canvas_diffing_mode_remaster: e.target.checked,
                  });
                }}
              />
            }
          />
        </Grid>
      </Grid>
    </Box>
  );
}
