import React from "react";
import { TableCell, TableRow, Typography } from "@mui/material";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import { cursorPointer } from "../../../sharedStyles";

export default function CanvasCourseRow({
  course,
  setDrawerStatusEdit,
  setActiveCourse,
}) {
  const handleOpenDrawer = () => {
    setActiveCourse(course);
    setDrawerStatusEdit(true);
  };

  return (
    <TableRow hover>
      <TableCell>
        <Typography>{course.name}</Typography>
      </TableCell>
      <TableCell>
        <Typography>{course.canvas_sis_id}</Typography>
      </TableCell>
      <TableCell>
        <Typography>{course.klasses_count}</Typography>
      </TableCell>
      <TableCell>
        <Typography>{course.school_year.name}</Typography>
      </TableCell>
      <TableCell sx={cursorPointer} onClick={() => handleOpenDrawer()}>
        <SettingsOutlinedIcon />
      </TableCell>
    </TableRow>
  );
}
