import axios from "../utils/axios-util";

const endpoint = "/api/canvas";

const auth = async (params) => {
  const { data } = await axios.get(`${endpoint}/request_token`, params);

  return data;
};

const exportCsv = async () => {
  const { data } = await axios.get(`${endpoint}/export`);

  return data;
};

const updateSchoolCanvasIntegration = async (
  canvasDomain,
  canvasSubAccountId,
  canvasRootAccountId,
  canvasClientId,
  canvasClientSecret,
  canvasRedirectUrl
) => {
  const { data } = await axios.patch(`${endpoint}/update_canvas_integration`, {
    canvas_domain: canvasDomain,
    canvas_sub_account_id: canvasSubAccountId,
    canvas_root_account_id: canvasRootAccountId,
    canvas_client_id: canvasClientId,
    canvas_client_secret: canvasClientSecret,
    canvas_redirect_url: canvasRedirectUrl,
  });
  if (!data) {
    console.error("Error updating canvas settings.");
    return { response: { data: { message: "Validation Error" } } };
  }

  return data;
};

const disconnect = async () => {
  const { data } = await axios.patch(`${endpoint}/disconnect_school`);
  if (!data) {
    console.error("Error disconnecting school");
    return { response: { data: { message: "Validation Error" } } };
  }

  return data;
};

const returnIntegrationInfo = async () => {
  const { data } = await axios.get(`${endpoint}/integration_info`);

  return data;
};

const checkPermission = async () => {
  const { data } = await axios.get(`${endpoint}/check_permission`);

  return data;
};

const fetchCanvasCourses = async () => {
  const { data } = await axios.get(`${endpoint}/canvas_courses`);

  return data;
};

const createCanvasCourse = async (params) => {
  const { data } = await axios.post(`${endpoint}/create_canvas_course`, params);

  if (!data.data) {
    console.error("Error creating course");
    return [];
  }
  return data;
};

const updateCanvasCourse = async (params) => {
  const { data } = await axios.patch(
    `${endpoint}/update_canvas_course`,
    params
  );

  if (!data.data) {
    console.error("Error updating course");
    return [];
  }
  return data;
};

const deleteCanvasCourse = async (courseId) => {
  const { data } = await axios.delete(`${endpoint}/${courseId}`);

  if (!data.data) {
    console.error("Error deleting course");
    return [];
  }
  return data;
};

const updateSettings = async (params) => {
  const { data } = await axios.patch(
    `${endpoint}/update_canvas_settings`,
    params
  );

  return data;
};

const getCanvasTerms = async () => {
  const { data } = await axios.get(`${endpoint}/canvas_terms`);

  return data;
};

export default {
  auth,
  exportCsv,
  returnIntegrationInfo,
  updateSchoolCanvasIntegration,
  disconnect,
  checkPermission,
  createCanvasCourse,
  fetchCanvasCourses,
  updateCanvasCourse,
  deleteCanvasCourse,
  updateSettings,
  getCanvasTerms,
};
