import {
  Button,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  useNavigate,
  useParams,
  Link as RouterLink,
  useSearchParams,
  Link,
} from "react-router-dom";
import admissionFormService from "../../../../service/admissionFormService";
import { cursorPointer } from "../../../sharedStyles";
import { FormStatusString, FormType } from "../FormBuilder/Models/Form";
import { formatDateTime } from "../../../../utils/constants/utils";
import tableStyles from "./formsList.style";
import SnackBarNotification from "../../../SnackBarNotification";

export default function FormsList() {
  const [searchParams] = useSearchParams();
  const [forms, setForms] = useState([]);
  const navigate = useNavigate();
  const params = useParams();
  const [hovering, setHovering] = useState(false);
  const [snackBarOpen, setSnackBarOpen] = useState({
    open: false,
    message: null,
  });

  const fetchAllForms = async () => {
    const response = await admissionFormService.fetchAllForms(
      searchParams.get("school_year")
    );
    setForms(response.data);
  };

  const formattedParams = (id) => {
    let paramsUrl = "?";
    const schoolYearId = searchParams.get("school_year");
    if (schoolYearId) {
      paramsUrl = paramsUrl.concat(`&school_year=${schoolYearId}`);
    }
    if (id) {
      paramsUrl = paramsUrl.concat(`&form_id=${id}`);
    }

    return paramsUrl;
  };

  const navigateToFormBuilder = (id) => {
    navigate(
      `/school/${
        params.school_id
      }/admissions/setup/form-builder${formattedParams(id)}`
    );
  };

  const fieldsCount = (form) =>
    form.admission_form_pages[0].form_sections.reduce(
      (totalCount, section) => totalCount + section.form_fields.length,
      0
    );

  const deleteForm = async (id) => {
    await admissionFormService
      .deleteForm(id)
      .then(() => {
        setSnackBarOpen({
          open: true,
          message: "Form deleted.",
          severity: "success",
        });
        fetchAllForms();
      })
      .catch((error) => {
        console.error("Error deleting form", error);
        setSnackBarOpen({
          open: true,
          message: "Error deleting form",
          severity: "error",
        });
      });
  };

  useEffect(() => {
    fetchAllForms();
  }, [searchParams.get("school_year")]);

  return (
    <Grid container justifyContent="center">
      <Grid item md={12}>
        <TableContainer>
          <Table sx={tableStyles}>
            <TableHead>
              <TableRow>
                <TableCell>NAME</TableCell>
                <TableCell>FIELDS</TableCell>
                <TableCell>DATE MODIFIED</TableCell>
                <TableCell>STATUS</TableCell>
                {forms.some((form) => form.type === FormType.INQUIRY) && (
                  <TableCell />
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {forms.map((form) => (
                <TableRow
                  key={form.id}
                  onMouseEnter={() => {
                    if (
                      form.form_type !== FormType.INQUIRY &&
                      !form.is_in_process
                    ) {
                      setHovering(true);
                    }
                  }}
                  onMouseLeave={() => {
                    if (
                      form.form_type !== FormType.INQUIRY &&
                      !form.is_in_process
                    ) {
                      setHovering(false);
                    }
                  }}
                >
                  <TableCell
                    sx={cursorPointer}
                    onClick={() => {
                      navigateToFormBuilder(form.id);
                    }}
                  >
                    {form.name}
                  </TableCell>
                  <TableCell align="center">{fieldsCount(form)}</TableCell>
                  <TableCell>{formatDateTime(form.updated_at)}</TableCell>
                  <TableCell align="center">
                    {FormStatusString[form.status]}
                  </TableCell>
                  {form.form_type === FormType.INQUIRY && (
                    <TableCell sx={cursorPointer}>
                      <Button
                        sx={{
                          backgroundColor: "#2196F3",
                          color: "white",
                          textTransform: "none",
                          "&:hover": {
                            backgroundColor: "#2196F3",
                          },
                        }}
                      >
                        <Link
                          to={`/inquiry/${form.school_year?.registration_token}`}
                          underline="none"
                          component={RouterLink}
                          sx={{
                            backgroundColor: "#2196F3",
                            color: "white",
                            textTransform: "none",
                            "&:hover": {
                              backgroundColor: "#2196F3",
                            },
                          }}
                          target="_blank"
                        >
                          View
                        </Link>
                      </Button>
                    </TableCell>
                  )}
                  {form.form_type !== FormType.INQUIRY &&
                  !form.is_in_process ? (
                    <TableCell sx={cursorPointer}>
                      <Button
                        variant="text"
                        onClick={() => {
                          deleteForm(form.id);
                        }}
                        sx={{
                          color: "#2196F3",
                          textTransform: "none",
                          opacity: hovering ? 1 : 0,
                          transition: "0.3s",
                        }}
                      >
                        Delete
                      </Button>
                    </TableCell>
                  ) : (
                    <TableCell />
                  )}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      <SnackBarNotification
        message={snackBarOpen.message}
        open={snackBarOpen.open}
        severity={snackBarOpen.severity}
        handleClose={() => setSnackBarOpen(false)}
      />
    </Grid>
  );
}
