const cSTableHead = (isActive = false) => ({
  borderBottom: "none",
  color: isActive ? "black" : "#808080",
  cursor: "pointer",
  padding: 0,
  userSelect: "none",
  "& p": {
    fontSize: "14px",
    display: "flex",
    alignItems: "end",
    justifyContent: "flex-start",
  },
});

const cSTableCell = () => ({
  color: "black",
  px: 0,
  "& p": {
    fontSize: "13px",
  },
});

const schoolYearForm = {
  width: "200px",
};

const schoolYearBox = {
  width: "200px",
  mr: "25px",
};

export { cSTableHead, cSTableCell, schoolYearForm, schoolYearBox };
