import React, { useEffect, useState } from "react";
import { useOutletContext } from "react-router-dom";
import { CircularProgress, Container, Stack } from "@mui/material";
import Authorize from "../../Authorize";
import IntegrationSetup from "./IntegrationSetup";
import CanvasSettings from "./CanvasSettings/CanvasSettings";
import CanvasCourses from "./CanvasCourses/CanvasCourses";
import canvasService from "../../../service/canvasService";
import { loader } from "../../sharedStyles";

export default function CanvasIntegration() {
  const [loading, setLoading] = useState(true);
  const [integrationInfo, setIntegrationInfo] = useState(null);
  const [, , canvasPermission] = useOutletContext();

  const getIntegrationInfo = async () => {
    const response = await canvasService.returnIntegrationInfo();

    if (response) {
      setIntegrationInfo(response);
    }

    setLoading(false);
  };

  useEffect(() => {
    getIntegrationInfo();
  }, []);

  if (canvasPermission) {
    return (
      <Authorize permission={canvasPermission}>
        {loading ? (
          <CircularProgress size={100} sx={loader} />
        ) : (
          <Container>
            <Stack
              direction="row"
              justifyContent="space-between"
              sx={{ borderBottom: "1px solid #BFC7CA" }}
            >
              <IntegrationSetup integrationInfo={integrationInfo} />
              {integrationInfo && (
                <CanvasSettings integrationInfo={integrationInfo} />
              )}
            </Stack>
            {integrationInfo && (
              <CanvasCourses integrationInfo={integrationInfo} />
            )}
          </Container>
        )}
      </Authorize>
    );
  }
}
