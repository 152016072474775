import React from "react";
import { Checkbox, TableCell, TableRow, Typography } from "@mui/material";
import { font16 } from "../../../sharedStyles";

export default function CanvasCoursesKlassRow({
  klass,
  setSelectedKlasses,
  activeCourse,
}) {
  const handleCheckboxChange = (kls, checked) => {
    if (checked) {
      setSelectedKlasses((prev) => [...prev, kls]);
    } else {
      setSelectedKlasses((prev) => prev.filter((k) => k.id !== kls.id));
    }
  };

  return (
    <TableRow hover tabIndex={-1} key={klass.id}>
      <TableCell padding="none" sx={{ width: "46px" }}>
        <Checkbox
          color="primary"
          defaultChecked={klass.canvas_course_id === activeCourse?.id}
          disabled={
            klass.canvas_course_id &&
            klass.canvas_course_id !== activeCourse?.id
          }
          onChange={(e) => handleCheckboxChange(klass, e.target.checked)}
        />
      </TableCell>
      <TableCell padding="none">
        <Typography
          sx={font16}
        >{`${klass.name} (${klass.abbreviation})`}</Typography>
      </TableCell>
    </TableRow>
  );
}
