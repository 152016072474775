import axios from "../utils/axios-util";

const klassesEndpoint = "/api/klasses";

const fetchAllKlasses = async (params) => {
  const { data } = await axios.get(klassesEndpoint, params);

  if (!data.data) {
    console.error("Error fetching klasses from the API");
    return [];
  }
  return data;
};

const fetchKlass = async (klassId) => {
  const { data } = await axios.get(`${klassesEndpoint}/${klassId}`);
  if (!data.data) {
    console.error("Error fetching klass from the API");
    return [];
  }
  return data;
};

const fetchYearKlasses = async (params) => {
  const { data } = await axios.get(
    `${klassesEndpoint}/klasses_in_current_year`,
    params
  );
  if (!data.data) {
    console.error("Error fetching klass from the API");
    return [];
  }
  return data;
};

const createKlass = async (params) => {
  const { data } = await axios.post(klassesEndpoint, params);

  if (!data.data) {
    console.error("Error fetching klass from the API");
    return [];
  }
  return data;
};

const updateKlass = async (params) => {
  const { data } = await axios.patch(
    `${klassesEndpoint}/${params.klass_id}`,
    params
  );

  if (!data.data) {
    console.error("Error fetching klass from the API");
    return [];
  }
  return data;
};

const connectGoogleClassroom = async (params) => {
  const { data } = await axios.patch(
    `${klassesEndpoint}/${params.klass_id}/gc_connect`,
    params
  );

  if (!data.data) {
    console.error("Error fetching klass from the API");
    return [];
  }
  return data;
};

const remove = async (id, params) => {
  const { data } = await axios.delete(`${klassesEndpoint}/${id}`, params);
  if (!data.data) {
    console.error("Error deleting class");
    return [];
  }
  return data;
};

export default {
  fetchKlass,
  fetchAllKlasses,
  fetchYearKlasses,
  createKlass,
  updateKlass,
  connectGoogleClassroom,
  remove,
};
