import * as React from "react";
import {
  Box,
  Container,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Avatar,
  Link,
  IconButton,
  Menu,
  MenuItem,
  Modal,
  Typography,
  Select,
  Button,
  InputLabel,
  FormControl,
  Checkbox,
} from "@mui/material";
import PrivacyTipOutlinedIcon from "@mui/icons-material/PrivacyTipOutlined";
import { Link as RouterLink, useParams } from "react-router-dom";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Check } from "@mui/icons-material";
import {
  studentTableBox,
  studentTableHeaderCell,
  studentTableCellWide,
  studentTablePaper,
  studentTableSpan,
  studentTableCell,
  studentTableHeadCell,
  studentTableHeadCellActive,
  studentsTableHeaderText,
  studentTableCellTeacher,
  tableStyles,
} from "./studentsTable.style";
import { tableAvatar, black } from "../../sharedStyles";
import StudentUserCard from "../../StudentInfo/UserCard/StudentUserCard";
import studentsService from "../../../service/studentsService";
import { PermissionsContext } from "../../../context/PermissionsContext";
import APP_PERMISSIONS from "../../../utils/constants/permissions";
import PERMISSION_TYPES from "../../../utils/constants/permission_types";
import { formatDateTime } from "../../../utils/constants/utils";
import { SnackbarContext } from "../../../context/SnackbarContext";
import ListPagination from "../../ListPagination";
import displayGradeLevel from "../../../utils/displayGradeLevel";

function EnhancedTableHead(props) {
  const {
    enrolledStudents,
    order,
    orderBy,
    onRequestSort,
    managePermission,
    numSelected,
    rowCount,
    onSelectAllClick,
  } = props;
  const headCells = [
    {
      id: "student",
      numeric: false,
      disablePadding: true,
      label: "NAME",
      sortable: true,
    },
    {
      id: "medical_badge",
      numeric: false,
      disablePadding: true,
      label: "",
      sortable: false,
      width: "5%",
    },
    {
      id: "grade-level",
      numeric: false,
      disablePadding: true,
      label: "GRADE",
      sortable: true,
      width: "10%",
    },
    {
      id: "teacher",
      numeric: false,
      disablePadding: true,
      label: "HOMEROOM TEACHER",
      sortable: true,
      width: "15%",
    },
    {
      id: "counselor_name",
      numeric: false,
      disablePadding: true,
      label: "COUNSELOR",
      sortable: true,
      width: "15%",
    },
    ...(enrolledStudents
      ? [
          {
            id: "enrolled_date",
            numeric: false,
            disablePadding: true,
            label: "ENROLLED DATE",
            sortable: true,
          },
        ]
      : []),
    ...(!enrolledStudents
      ? [
          {
            id: "withdrawn_date",
            numeric: false,
            disablePadding: true,
            label: "WITHDRAWAL DATE",
            sortable: true,
          },
        ]
      : []),
    {
      id: "imported",
      numeric: false,
      disablePadding: true,
      label: "IMPORT",
      sortable: "false",
    },
    {
      id: "import_date",
      numeric: false,
      disablePadding: true,
      label: "IMPORT DATE",
      sortable: "false",
    },
    {
      id: "context_menu",
      numeric: false,
      disablePadding: true,
      label: "",
      sortable: false,
      needPermission: true,
    },
  ];

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  const isHeaderActive = (id, sortable) =>
    orderBy === id
      ? studentTableHeadCellActive(sortable)
      : studentTableHeadCell(sortable);

  const renderSortChevron = (id) => {
    if (id !== orderBy) return "";

    return order === "asc" ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />;
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              "aria-label": "select all students",
            }}
          />
        </TableCell>
        <TableCell />
        {headCells.map(
          (headCell) =>
            (!headCell.needPermission || managePermission) && (
              <TableCell
                {...(headCell.sortable && {
                  onClick: createSortHandler(headCell.id),
                })}
                key={headCell.id}
                align="left"
                padding={headCell.disablePadding ? "none" : "normal"}
                sx={isHeaderActive(headCell.id, headCell.sortable)}
                width={headCell.width}
                sortDirection={orderBy === headCell.id ? order : false}
              >
                <Box sx={studentsTableHeaderText}>
                  {headCell.label}
                  {renderSortChevron(headCell.id)}
                </Box>
              </TableCell>
            )
        )}
      </TableRow>
    </TableHead>
  );
}

function StudentsTable({
  isCompact,
  students,
  setStudents = () => {},
  searchParams,
  setSearchParams,
  selected,
  order,
  orderBy,
  setOrderBy,
  setOrder,
  setSelected,
  setPage,
  totalPages,
  page,
  registeredStudents,
  enrolledStudents,
  count,
  setCount,
  totalStudents,
  currentSchool = {},
}) {
  // eslint-disable-next-line no-unused-vars
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const params = useParams();
  const schoolId = params.school_id;
  const [currentStudent, setCurrentStudent] = React.useState(null);
  const [importStudent, setImportStudent] = React.useState(null);
  const [anchorElModal, setAnchorElModal] = React.useState(null);
  const [importError, setImportError] = React.useState("");
  const openMenu = Boolean(anchorElModal);
  const [openModal, setOpenModal] = React.useState(false);
  const snackbarContext = React.useContext(SnackbarContext);
  const [importingData, setImportingData] = React.useState(false);
  const handleModalOpen = () => setOpenModal(true);
  const handleModalClose = () => {
    setOpenModal(false);
    setAnchorElModal(null);
    setImportStudent(null);
    setImportError("");
  };

  const { hasPermission, hasAnyPermissionType } =
    React.useContext(PermissionsContext);

  const managePermission = hasPermission(
    APP_PERMISSIONS.ADMISSIONS,
    PERMISSION_TYPES.MANAGE
  );

  const studentAnyPermission = hasAnyPermissionType(APP_PERMISSIONS.ADMISSIONS);

  const handleImport = async () => {
    snackbarContext.setSnackbar({
      message: "Importing student.",
      severity: "info",
      open: true,
    });
    setImportingData(true);
    if (!currentSchool.admission_version_advanced) {
      try {
        const response = await studentsService.copyStudentData(
          currentStudent.id,
          {
            school_year_application_id: importStudent.id,
          }
        );

        if (response.data) {
          setImportStudent(null);
          handleModalClose();
          snackbarContext.setSnackbar({
            message: "Student imported.",
            severity: "success",
            open: true,
          });
        }
        setImportingData(false);
      } catch (error) {
        setImportingData(false);
        snackbarContext.setSnackbar({
          message: "Unable to import.",
          severity: "error",
          open: true,
        });
      }

      return;
    }

    studentsService
      .importStudentsData(currentStudent.id, importStudent.id)
      .then(() => {
        setImportingData(false);
        const updatingStudents = [...students];
        const index = updatingStudents.findIndex(
          (s) => s.id === currentStudent.id
        );
        updatingStudents[index].import_date = new Date();
        updatingStudents[index].grade_level = importStudent.grade_level;
        setStudents(updatingStudents);
        setImportStudent(null);
        handleModalClose();
        snackbarContext.setSnackbar({
          message: "Student imported.",
          severity: "success",
          open: true,
        });
      })
      .catch(() => {
        setImportingData(false);
        snackbarContext.setSnackbar({
          message: "Unable to import.",
          severity: "error",
          open: true,
        });
      });
  };

  const handleModalClick = async (event, student) => {
    setCurrentStudent(student);
    setAnchorElModal(event.currentTarget);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    const direction = isAsc ? "desc" : "asc";
    setOrder(direction);
    setOrderBy(property);

    searchParams.set("sort", property);
    searchParams.set("direction", direction);
    setSearchParams(searchParams);
  };

  const handleChangePage = (event, value) => {
    setPage(value);
    searchParams.set("page", value);
    setSearchParams(searchParams);
  };

  const homeroomTeacher = (_student) =>
    _student.teacher?.first_name
      ? `${_student.teacher?.first_name} ${_student.teacher?.last_name}`
      : "-";

  const [anchorEl, setAnchorEl] = React.useState(null);
  const showStudentUserCard = Boolean(anchorEl);

  const avatarClick = async (event, student) => {
    setCurrentStudent(student);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const getEnrolledWithdrawalDate = (enrolledDate) => {
    if (enrolledDate) {
      const date = new Date(enrolledDate.replace("Z", ""));
      return date.toLocaleDateString("en-US", {
        month: "long",
        day: "numeric",
        year: "numeric",
      });
    }
    return "-";
  };

  function handleProfilePictureSrc(student) {
    if (student?.image_url) {
      return (
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions
        <img
          alt="profile_image"
          src={student.image_url.url}
          style={{
            borderRadius: "100px",
            width: "40px",
            height: "40px",
            objectFit: "cover",
          }}
          onClick={(e) => {
            avatarClick(e, student);
          }}
        />
      );
    }
    return (
      <Avatar
        sx={tableAvatar}
        onClick={(e) => {
          avatarClick(e, student);
        }}
      >
        {student.first_name.charAt(0).toUpperCase()}
      </Avatar>
    );
  }

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = students.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  return (
    <Box sx={studentTableBox}>
      <Container sx={{ px: "7rem !important" }} maxWidth="xl">
        <Paper sx={studentTablePaper} elevation={0}>
          <TableContainer>
            <Table aria-labelledby="tableTitle" size="medium" sx={tableStyles}>
              {!isCompact && (
                <EnhancedTableHead
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={handleSelectAllClick}
                  onRequestSort={handleRequestSort}
                  rowCount={students.length}
                  managePermission={managePermission}
                  enrolledStudents={enrolledStudents}
                />
              )}
              <TableBody>
                {students.map((_student) => {
                  const labelId = `enhanced-table-checkbox-${_student.id}`;

                  return (
                    <TableRow hover tabIndex={-1} key={_student.id}>
                      <TableCell padding="checkbox">
                        <Checkbox
                          color="primary"
                          checked={isSelected(_student.id)}
                          onChange={(event) => handleClick(event, _student.id)}
                          inputProps={{
                            "aria-labelledby": labelId,
                          }}
                        />
                      </TableCell>
                      <TableCell
                        component="th"
                        id={labelId}
                        scope="row"
                        padding="none"
                        sx={studentTableHeaderCell(isCompact)}
                      >
                        {handleProfilePictureSrc(_student)}
                      </TableCell>
                      <TableCell
                        component="th"
                        id={labelId}
                        scope="row"
                        padding="none"
                        sx={studentTableCellWide(isCompact)}
                      >
                        {studentAnyPermission ? (
                          <Link
                            to={`/school/${schoolId}/students/${_student.slug}/account`}
                            underline="none"
                            component={RouterLink}
                          >
                            <span style={studentTableSpan(isCompact)}>
                              {`${_student.last_name}, ${_student.first_name} ${
                                _student?.middle_name ?? ""
                              }`}
                            </span>
                          </Link>
                        ) : (
                          <Box>
                            <span style={studentTableSpan(isCompact)}>
                              {`${_student.first_name} ${
                                _student.middle_name || ""
                              } ${_student.last_name}`}
                            </span>
                          </Box>
                        )}
                      </TableCell>
                      <TableCell>
                        {_student.medical_badge && <PrivacyTipOutlinedIcon />}
                      </TableCell>
                      <TableCell>
                        {displayGradeLevel(_student.grade_level)}
                      </TableCell>

                      {!isCompact && (
                        <>
                          <TableCell
                            align="center"
                            sx={studentTableCellTeacher}
                          >
                            {homeroomTeacher(_student)}
                          </TableCell>
                          <TableCell sx={studentTableCellTeacher}>
                            {_student.counselor_name || "-"}
                          </TableCell>
                          <TableCell sx={studentTableCell} align="center">
                            {getEnrolledWithdrawalDate(
                              _student.enrollments[0]?.date
                            )}
                          </TableCell>
                          <TableCell align="center" sx={studentTableCell}>
                            {(_student.import_date && <Check />) || "-"}
                          </TableCell>
                          <TableCell align="center" sx={studentTableCell}>
                            {(_student.import_date &&
                              formatDateTime(_student.import_date)) ||
                              "-"}
                          </TableCell>
                          {managePermission && (
                            <TableCell
                              sx={{ color: "#808080", cursor: "pointer" }}
                            >
                              <IconButton
                                sx={black}
                                aria-label="more"
                                id="long-button"
                                aria-haspopup="true"
                                onClick={(e) => handleModalClick(e, _student)}
                              >
                                <MoreVertIcon />
                              </IconButton>
                            </TableCell>
                          )}
                        </>
                      )}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          {!isCompact && (
            <ListPagination
              totalPages={totalPages}
              handleChangePage={handleChangePage}
              page={page}
              count={count}
              setCount={setCount}
              total={totalStudents}
            />
          )}
        </Paper>
      </Container>
      <StudentUserCard
        studentId={currentStudent?.id}
        schoolId={schoolId}
        handleClose={handleClose}
        showStudentUserCard={showStudentUserCard}
        anchorEl={anchorEl}
      />
      <Menu
        id="long-menu"
        anchorEl={anchorElModal}
        open={openMenu}
        onClose={handleModalClose}
      >
        <MenuItem autoFocus={false} onClick={handleModalOpen}>
          Import Registration
        </MenuItem>
      </Menu>
      <Modal
        open={openModal}
        onClose={handleModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 500,
            bgcolor: "background.paper",
            borderRadius: "20px",
            boxShadow: 24,
            p: 4,
          }}
        >
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Import student registration data
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Select a student from the enrolled student list below. If you do not
            see the student in the list, first enrolled the student in your
            school in the Student ID Manager
          </Typography>
          <Box
            pt={2}
            sx={{
              display: "flex",
              mt: "15px",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <FormControl fullWidth>
              <InputLabel id="registered_students">
                {registeredStudents.length === 0
                  ? "No registrations for this school year"
                  : "Registered Students"}
              </InputLabel>
              <Select
                defaultValue=""
                disabled={registeredStudents.length === 0}
                label="Registered Students"
                onChange={(e) => setImportStudent(e.target.value)}
              >
                {registeredStudents.map((_student) => (
                  <MenuItem value={_student} key={_student.id}>
                    {`${_student.first_name} ${_student?.middle_name ?? ""} ${
                      _student.last_name
                    }`}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Box ml={2}>
              <Button
                sx={{
                  backgroundColor: "#2196F3",
                  padding: "2px 15px",
                  height: "40px",
                  textTransform: "none",
                }}
                disabled={!importStudent || importingData}
                variant="contained"
                onClick={() => handleImport()}
              >
                Import
              </Button>
            </Box>
          </Box>
          {importError !== "" && (
            <Box mt={2}>
              <Typography sx={{ color: "red" }}>{importError}</Typography>
            </Box>
          )}
        </Box>
      </Modal>
    </Box>
  );
}

export default StudentsTable;
