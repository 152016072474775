import { Box, Typography } from "@mui/material";
import React, { forwardRef } from "react";
import SectionLayout from "./SectionLayout";

// eslint-disable-next-line import/prefer-default-export
export const PrintFormLayout = forwardRef((props, ref) => {
  const { form } = props;

  return (
    <div ref={ref}>
      <Typography variant="h2" align="center" fontWeight={800}>
        {form.schoolName}
      </Typography>
      <Typography variant="h6" align="center">
        ADMISSIONS FORM: {form.name?.toUpperCase()} {form.schoolYearName}
      </Typography>
      {form.formPages.map((page) => (
        <Box
          key={page.id}
          elevation={3}
          sx={{
            padding: 4,
            maxWidth: 800,
            margin: "auto",
            pageBreakAfter: "always",
            breakAfter: "page",
          }}
        >
          {page.sections.map((section) => (
            <SectionLayout section={section} />
          ))}
        </Box>
      ))}
    </div>
  );
});
