import React from "react";
import { FormProvider, Controller } from "react-hook-form";
import {
  Stack,
  Button,
  Typography,
  Grid,
  Box,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import {
  formHeader,
  formButtons,
  formTitle,
  formButtonsContainer,
  formContainer,
  mr15,
  my30,
  bgWhite,
  mb30,
} from "../../sharedStyles";
import GenericSubmitButton from "../../GenericSubmitButton";
import ErasableTextField from "../../ErasableTextField";
import { renderError, requiredMessage } from "../../../utils/constants/forms";
import moment from "../../../utils/constants/momentConfig";
import { schoolYearForm, schoolYearBox } from "./CommunityServiceTable.styles";

export default function CommunityServiceForm({
  activeService,
  handleDrawerClose,
  methods,
  onSubmit,
  setErrors,
  handleDeleteDialog,
}) {
  const handleError = (error, field) => {
    if (error === "invalidDate") {
      setErrors(true);
      methods.setError(field, {
        type: "custom",
        message: "Wrong format",
      });
    } else {
      setErrors(false);
      methods.clearErrors(field);
    }
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <Stack direction="row" sx={formHeader}>
          <Grid container item sm={5}>
            <Typography sx={formTitle}>
              {activeService ? "Edit" : "Add"} Service Hours
            </Typography>
          </Grid>
          <Grid container item sm={7}>
            <Stack sx={formButtonsContainer} direction="row">
              <Button onClick={() => handleDrawerClose()} sx={formButtons}>
                Cancel
              </Button>
              {activeService && (
                <GenericSubmitButton
                  text="Delete"
                  submittingText="Deleting..."
                  type="button"
                  onClick={() => handleDeleteDialog(activeService)}
                />
              )}
              <GenericSubmitButton text="Save" submittingText="Saving..." />
            </Stack>
          </Grid>
        </Stack>
        <Box sx={formContainer}>
          <Stack direction="row">
            <Box sx={[schoolYearBox, mb30]}>
              <FormControl
                fullWidth
                sx={schoolYearForm}
                error={methods.formState.errors?.school_year}
              >
                <InputLabel id="school_year" shrink sx={bgWhite}>
                  Year*
                </InputLabel>
                <Select
                  labelId="school_year"
                  id="school_year"
                  defaultValue={activeService?.school_year}
                  {...methods.register("school_year", {
                    required: requiredMessage,
                  })}
                >
                  <MenuItem value="2018-2019">2018-2019</MenuItem>
                  <MenuItem value="2019-2020">2019-2020</MenuItem>
                  <MenuItem value="2020-2021">2020-2021</MenuItem>
                  <MenuItem value="2021-2022">2021-2022</MenuItem>
                  <MenuItem value="2022-2023">2022-2023</MenuItem>
                  <MenuItem value="2023-2024">2023-2024</MenuItem>
                  <MenuItem value="2024-2025">2024-2025</MenuItem>
                </Select>
              </FormControl>

              {methods.formState.errors?.school_year &&
                renderError(methods.formState.errors?.school_year.message)}
            </Box>
          </Stack>
          <Stack direction="row">
            <Box sx={[mr15, { width: "100%" }]}>
              <Controller
                name="date"
                control={methods.control}
                defaultValue={
                  activeService?.date
                    ? moment(activeService.date).format("MM/DD/YYYY")
                    : ""
                }
                rules={{
                  pattern: {
                    value:
                      /(0[1-9]|1[0-2])\/(0[1-9]|[12]\d|3[01])\/(19|20)\d{2}/,
                    message: "Invalid date.",
                  },
                }}
                render={({ field: { onChange, value } }) => {
                  const dateValue =
                    value && moment(value, "MM/DD/YYYY", true).isValid()
                      ? moment(value, "MM/DD/YYYY")
                      : null;

                  return (
                    <DatePicker
                      label="Date"
                      id="date"
                      value={dateValue}
                      onError={(newError) => handleError(newError, "date")}
                      onChange={(e) => {
                        if (!e || e === "Invalid Date") {
                          onChange(null);
                        } else {
                          const formattedDate = moment(e).isValid()
                            ? moment(e).format("MM/DD/YYYY")
                            : null;
                          onChange(formattedDate);
                        }
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          error={!!methods.formState.errors?.date}
                          sx={{ width: "100%" }}
                        />
                      )}
                    />
                  );
                }}
              />

              {methods.formState.errors?.date &&
                renderError(methods.formState.errors?.date.message)}
            </Box>

            <TextField
              id="hours"
              label="Hours"
              fullWidth
              type="number"
              defaultValue={activeService?.hours || null}
              hasError={false}
              inputProps={{
                step: "0.01",
              }}
              error={!!methods.errors?.hours}
              {...methods.register("hours", {
                required: true,
              })}
              required
            />
          </Stack>
          <Box sx={my30}>
            <ErasableTextField
              id="description"
              label="Description"
              fullWidth
              type="text"
              defaultValue={activeService?.description || null}
              isMultiline
              lines={4}
            />
          </Box>
          <Box sx={my30}>
            <Stack direction="row">
              <ErasableTextField
                id="verified_by"
                label="Verified By"
                fullWidth
                inputSx={mr15}
                defaultValue={activeService?.verified_by || null}
              />
              <ErasableTextField
                id="supervisor"
                label="Supervisor"
                fullWidth
                defaultValue={activeService?.supervisor || null}
              />
            </Stack>
          </Box>
          <Box sx={my30}>
            <ErasableTextField
              id="requirement"
              label="Requirement Area"
              fullWidth
              defaultValue={activeService?.requirement || null}
            />
          </Box>
          <Box sx={{ borderBottom: "1px solid #BFC7CA", pb: "30px" }}>
            <ErasableTextField
              id="service_type"
              label="Service Type"
              fullWidth
              defaultValue={activeService?.service_type || null}
            />
          </Box>
          <Box sx={my30}>
            <ErasableTextField
              id="notes"
              label="Notes"
              fullWidth
              type="text"
              defaultValue={activeService?.notes || null}
              isMultiline
              lines={4}
            />
          </Box>
        </Box>
      </form>
    </FormProvider>
  );
}
