import axios from "../utils/axios-util";

const employmentsEndpoint = "/api/employments";

const updateKlassesOrder = async (params) => {
  const { data } = await axios.put(
    `${employmentsEndpoint}/update_klasses_order`,
    params
  );

  return data;
};

const fetchCurrentEmployment = async () => {
  const { data } = await axios.get(`${employmentsEndpoint}/current`);
  return data;
};

export default { updateKlassesOrder, fetchCurrentEmployment };
