import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  CircularProgress,
  Drawer,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import {
  font28,
  loader,
  my30,
  rightDrawerContainer,
} from "../../../sharedStyles";
import CanvasCoursesForm from "./CanvasCoursesForm";
import { QuickBarContext } from "../../../../context/QuickBarContext";
import canvasService from "../../../../service/canvasService";
import CanvasCourseRow from "./CanvasCourseRow";
import AlertDialog from "../../../AlertDialog";
import { SnackbarContext } from "../../../../context/SnackbarContext";
import courseService from "../../../../service/courseService";

export default function CanvasCourses() {
  const [drawerStatusEdit, setDrawerStatusEdit] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [canvasCourses, setCanvasCourses] = useState([]);
  const [courses, setCourses] = useState([]);
  const [activeCourse, setActiveCourse] = useState(false);
  const snackbarContext = useContext(SnackbarContext);

  const quickBarContext = useContext(QuickBarContext);

  const getAEConnectCourses = async () => {
    const response = await courseService.fetchAllCourses({
      params: {
        order: "desc",
        orderBy: "name",
      },
    });
    if (response.data) {
      setCourses(response.data);
    }
  };

  const handleCloseDrawer = () => () => {
    setDrawerStatusEdit(false);
    setActiveCourse(false);
    quickBarContext.hideCanvasCourse();
  };

  const getCourses = async () => {
    const response = await canvasService.fetchCanvasCourses();

    if (response) {
      setCanvasCourses(response);
    }
  };

  const handleDeleteCourse = async () => {
    const response = await canvasService.deleteCanvasCourse(activeCourse.id);

    if (response) {
      setDrawerStatusEdit(false);
      setActiveCourse(false);
      setDeleteDialogOpen(false);

      snackbarContext.setSnackbar({
        message: "Course deleted.",
        severity: "success",
        open: true,
      });
    }
  };

  useEffect(() => {
    if (quickBarContext.canvasCourse) {
      setDrawerStatusEdit(true);
    }
  }, [quickBarContext.canvasCourse]);

  useEffect(() => {
    getAEConnectCourses();
    getCourses();
  }, [drawerStatusEdit]);

  return (
    <Box sx={my30}>
      <Typography sx={font28}>Canvas Courses</Typography>

      <Box>
        {canvasCourses.length > 0 ? (
          <TableContainer>
            <Table size="medium">
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Typography>Name</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>SIS ID</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>Classes</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>School Year</Typography>
                  </TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {canvasCourses.map((course) => (
                  <CanvasCourseRow
                    key={course.id}
                    course={course}
                    setDrawerStatusEdit={setDrawerStatusEdit}
                    setActiveCourse={setActiveCourse}
                  />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <Typography align="center">No courses found</Typography>
        )}
      </Box>

      <Drawer anchor="right" open={drawerStatusEdit}>
        <Box sx={rightDrawerContainer}>
          {courses.length > 0 ? (
            <CanvasCoursesForm
              handleCloseDrawer={handleCloseDrawer()}
              activeCourse={activeCourse}
              setDeleteDialogOpen={setDeleteDialogOpen}
              courses={courses}
            />
          ) : (
            <CircularProgress size={100} sx={loader} />
          )}
        </Box>
      </Drawer>

      <AlertDialog
        isOpen={deleteDialogOpen}
        handleClose={() => setDeleteDialogOpen(false)}
        handleConfirm={handleDeleteCourse}
      />
    </Box>
  );
}
