import React, { useContext, useEffect, useState } from "react";
import { Box, Grid, Typography } from "@mui/material";
import { containerStyle } from "../FormBuilder/FormBuilder.style";
import { FormBuilderContext } from "../FormBuilder/FormBuilderContext";
import { formTitle } from "../../../sharedStyles";
import FieldRenderer from "./FieldRenderer";
import formRenderMap from "./formRenderMap";

export default function FormSectionRenderer({
  section,
  sectionIndex,
  formattedView = false,
  dataView,
  guardianView,
  parentSectionIndex,
  emergencyContactSectionIndex,
  key,
}) {
  const formBuilderContext = useContext(FormBuilderContext);
  const { form } = formBuilderContext;

  const setCurrentSection = () => {
    formBuilderContext.updateCurrentSectionIndex(sectionIndex);
  };

  const [studentLabelToDbFieldMap, setStudentLabelToDbFieldMap] =
    useState(null);
  const [parentLabelToDbFieldMap, setParentLabelToDbFieldMap] = useState(null);
  const emergencyContactDataMap = {
    "First Name": "first_name",
    "Middle Name": "middle_name",
    "Last Name": "last_name",
    "Cell Phone": "cell_phone",
    "Secondary Phone": "secondary_phone",
    Email: "email",
    "Primary Contact": "is_primary",
    "Authorized Pickup": "is_authorized_pickup",
    "Relationship to the student": "relationship",
    "Vehicle License": "vehicle_license",
    "School Transportation": "school_transportation",
    "Background Check": "background_check",
    Text: "text_notifications",
    Notes: "notes",
  };

  const getDbField = (label, isParent = false, isEmergencyContact = false) => {
    if (!parentLabelToDbFieldMap || !studentLabelToDbFieldMap) {
      return null;
    }
    let map;
    if (isParent) {
      map = parentLabelToDbFieldMap;
    } else if (isEmergencyContact) {
      map = emergencyContactDataMap;
    } else {
      map = studentLabelToDbFieldMap;
    }
    const dbField = map[label];
    return dbField || label?.trim().toLowerCase().replace(/ /g, "_");
  };

  useEffect(() => {
    const mapFields = (fields, defaultMap) =>
      fields.reduce((acc, field) => {
        const normalizedLabel = field.label
          .trim()
          .toLowerCase()
          .replace(/ /g, "_");
        acc[field.label] = defaultMap[field.label] || normalizedLabel;
        return acc;
      }, defaultMap);

    if (form.studentCustomFields) {
      const defaultStudentFieldsMap = {
        "Full Legal Name": "legal_name",
        "First Name": "first_name",
        "Middle Name": "middle_name",
        "Last Name": "last_name",
        "Preferred Name": "preferred_name",
        Birthdate: "date_of_birth",
        "Place of Birth": "place_of_birth",
        Gender: "gender",
        Ethnicity: "ethnicity",
        Language: "language",
        Denomination: "denomination",
        "Home Church": "home_church",
        "Primary Email": "primary_email",
        "Secondary Email": "secondary_email",
        "Student Mobile Number": "student_phone_number",
        "Doctor Name": "doctor_name",
        "Dentist Name": "dentist_name",
        "Insurance company Name": "insurance_company_name",
        "Policy Number": "insurance_policy_number",
        "Baptism Date": "baptism_year",
      };
      const mappedFields = mapFields(
        form.studentCustomFields,
        defaultStudentFieldsMap
      );
      setStudentLabelToDbFieldMap(mappedFields);
    }

    if (form.guardianCustomFields) {
      const defaultParentFieldsMap = {
        "First Name": "first_name",
        "Middle Name": "middle_name",
        "Last Name": "last_name",
        "Cell Phone": "cell_phone",
        "Secondary Phone": "secondary_phone",
        Email: "email",
        "Home Church": "home_church",
        "Is Adventist": "is_adventist",
        "Primary Contact": "is_primary_contact",
        "Approved Volunteer": "is_approved_volunteer",
        "Emergency Contact": "is_emergency_contact",
        "Custodial Parent": "is_custodial_parent",
        "Authorized Pickup": "authorized_pickup",
        "School Transportation": "school_transportation",
        Text: "text_notifications",
        Notes: "notes",
        "Relationship to the student": "relationship",
        "Address 1": "address_line1",
        "Address 2": "address_line2",
        City: "city",
        State: "state",
        "Postal Code": "postal_code",
        Country: "country",
      };
      const mappedFields = mapFields(
        form.guardianCustomFields,
        defaultParentFieldsMap
      );
      setParentLabelToDbFieldMap(mappedFields);
    }
  }, [form.studentCustomFields, form.guardianCustomFields]);

  return (
    studentLabelToDbFieldMap &&
    parentLabelToDbFieldMap && (
      <Box
        onClick={setCurrentSection}
        container
        sx={containerStyle(formattedView)}
        key={key}
      >
        <Grid mb={2} container justifyContent="space-between">
          <Typography color="black" sx={formattedView ? formTitle : ""}>
            {section.name}
          </Typography>
        </Grid>
        <Grid
          container
          columnSpacing={2}
          rowGap={formattedView ? 0 : 2}
          alignItems="center"
        >
          {section.fields.map((field) => (
            <FieldRenderer
              key={`${key}-${field.fieldId}`}
              field={field}
              getDbField={getDbField}
              form={form}
              section={section}
              guardianView={guardianView}
              dataView={dataView}
              formattedView={formattedView}
              formRenderMap={formRenderMap}
              parentSectionIndex={parentSectionIndex}
              emergencyContactSectionIndex={emergencyContactSectionIndex}
            />
          ))}
        </Grid>
      </Box>
    )
  );
}
