import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { useForm, useFieldArray } from "react-hook-form";
import { useParams, useSearchParams } from "react-router-dom";
import { QuickBarContext } from "../../context/QuickBarContext";
import {
  gpaCalcWidth,
  gpaCodeWidth,
  gpaPassWidth,
  gpaScaleCol,
  gpaScaleName,
  gpaScaleType,
  gpaUgpaWidth,
  gpaWidth,
  gradesBox,
  gradesText,
} from "./GPA.styles";
import { cursorPointer, mt35, py10, textCenter } from "../sharedStyles";
import GPAScaleRows from "./GPAScaleRows";
import { useUnsavedChanges } from "../../context/UnsavedChangesContext";
import SaveChangesPopper from "../SaveChangesPopper";
import { PermissionsContext } from "../../context/PermissionsContext";
import APP_PERMISSIONS from "../../utils/constants/permissions";
import PERMISSION_TYPES from "../../utils/constants/permission_types";
import Authorize from "../Authorize";
import gpaScaleService from "../../service/gpaScaleService";
import { SnackbarContext } from "../../context/SnackbarContext";
import schoolsService from "../../service/schoolsService";
import SnackBarNotification from "../SnackBarNotification";

export default function GPA() {
  const [saveChangesOpen, setSaveChangesOpen] = useState(false);
  const quickBarContext = useContext(QuickBarContext);
  const { setUnsavedChanges } = useUnsavedChanges();
  const { control, register, handleSubmit, setValue } = useForm();
  const { fields, append, remove } = useFieldArray({
    control,
    name: "collections",
  });
  const { hasAnyPermissionType, hasPermission } =
    useContext(PermissionsContext);
  const managePermission = hasPermission(
    APP_PERMISSIONS.GPA,
    PERMISSION_TYPES.MANAGE
  );
  const [searchParams] = useSearchParams();
  const schoolYearId = searchParams.get("school_year");
  const [initialState, setInitialState] = useState(true);
  const snackbarContext = useContext(SnackbarContext);
  const [removedScales, setRemovedScales] = useState([]);
  const [gpaEnabledGrades, setGpaEnabledGrades] = useState([]);
  const [snackBarOpen, setSnackBarOpen] = useState({
    open: false,
    message: null,
  });
  const params = useParams();
  const schoolId = params.school_id;

  const handleAddScale = () => {
    append();
    quickBarContext.hideAddGPAScale();
    setInitialState(false);
  };

  const getGpaScales = async () => {
    const response = await gpaScaleService.fetchAll({
      params: {
        school_year_id: schoolYearId,
      },
    });

    fields.map((item) => remove(item));

    if (response.data) {
      response.data.map((data) =>
        append({
          id: data.id,
          record_id: data.id,
          name: data.name,
          scale_type: data.scale_type,
          gpa_scale_codes: data.gpa_scale_codes,
        })
      );
    }

    if (response.gpa_enabled_grades) {
      setGpaEnabledGrades(response?.gpa_enabled_grades);
    }
  };

  const onSubmit = async (data) => {
    const response = await gpaScaleService
      .updateGpaScales({
        school_year_id: schoolYearId,
        gpa_scales: data.collections,
        removed_scales: removedScales,
      })
      .catch((err) =>
        snackbarContext.setSnackbar({
          message: err.response.data.message,
          severity: "error",
          open: true,
        })
      );

    if (response.data) {
      fields.map((item) => remove(item));
      response.data.map((res) =>
        append({
          id: res.id,
          record_id: res.id,
          name: res.name,
          scale_type: res.scale_type,
          gpa_scale_codes: res.gpa_scale_codes,
        })
      );
      setInitialState(true);
      setRemovedScales([]);

      snackbarContext.setSnackbar({
        open: true,
        message: "Saved successfully.",
        severity: "success",
      });
      setSaveChangesOpen(false);
    }
  };

  useEffect(() => {
    if (!initialState) {
      setUnsavedChanges(true);
      setSaveChangesOpen(true);
    }
  }, [fields, initialState]);

  useEffect(() => {
    if (quickBarContext.addGPAScale) {
      handleAddScale();
    }
  }, [quickBarContext.addGPAScale]);

  useEffect(() => {
    (async () => {
      if (schoolYearId) {
        await getGpaScales();
      }
    })();
  }, [schoolYearId]);

  const updateSchoolGpaEnabledGrades = async (grades) => {
    try {
      const response = await schoolsService.updateSchoolGpaEnabledGrades(
        schoolId,
        grades
      );
      if (response) {
        setSnackBarOpen({
          open: true,
          message: "Saved successfully",
          severity: "success",
        });
      }
    } catch (error) {
      setSnackBarOpen({
        open: true,
        message: error.message,
        severity: "error",
      });
    }
  };

  return (
    <Authorize permission={hasAnyPermissionType(APP_PERMISSIONS.GPA)}>
      {fields.length > 0 ? (
        <Box>
          <Grid item sm={12} sx={gradesBox}>
            <Typography sx={gradesText}>
              Enable GPA for the following grades:
            </Typography>

            <FormGroup
              sx={{ display: "inline" }}
              onClick={(e) => {
                const grade = e.target.value;
                let grades = gpaEnabledGrades;
                if (e.target.checked) {
                  grades.push(grade);
                } else {
                  grades = grades.filter((item) => item !== grade);
                }
                setGpaEnabledGrades(grades);

                updateSchoolGpaEnabledGrades(grades);
              }}
            >
              {["6", "7", "8"].map((item) => (
                <FormControlLabel
                  value={item}
                  control={
                    <Checkbox
                      defaultChecked={gpaEnabledGrades.includes(item)}
                      key={item}
                    />
                  }
                  label={item}
                  key={item}
                />
              ))}
            </FormGroup>
          </Grid>

          <SnackBarNotification
            message={snackBarOpen.message}
            open={snackBarOpen.open}
            severity={snackBarOpen.severity}
            handleClose={() => setSnackBarOpen(false)}
          />

          <Stack direction="row">
            {fields.map((field, index) => (
              <Box sx={gpaScaleCol} key={field.id}>
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <FormControl sx={gpaScaleName}>
                    <TextField
                      disabled={!managePermission}
                      {...register(`collections.${index}.name`)}
                      size="small"
                      label="Name"
                      onChange={() => setInitialState(false)}
                    />
                  </FormControl>
                  <FormControl size="small" sx={gpaScaleType}>
                    <InputLabel>Type</InputLabel>
                    <Select
                      disabled={!managePermission}
                      defaultValue={field.scale_type}
                      {...register(`collections.${index}.scale_type`)}
                      label="Type"
                      onChange={() => setInitialState(false)}
                    >
                      <MenuItem value="letter">Letter</MenuItem>
                      <MenuItem value="number">Number</MenuItem>
                    </Select>
                  </FormControl>
                  {managePermission && (
                    <DeleteIcon
                      sx={cursorPointer}
                      onClick={() => {
                        remove(index);
                        setRemovedScales((oldArray) => [
                          ...oldArray,
                          field.record_id,
                        ]);
                        setInitialState(false);
                      }}
                    />
                  )}
                </Stack>
                <Stack direction="row" sx={py10}>
                  <Typography sx={gpaCodeWidth}>Code</Typography>
                  <Typography sx={gpaWidth}>GPA</Typography>
                  <Typography sx={gpaUgpaWidth}>UGPA</Typography>
                  <Typography sx={gpaPassWidth}>Pass</Typography>
                  <Typography sx={gpaCalcWidth}>Calc.</Typography>
                </Stack>
                <Divider />
                <GPAScaleRows
                  nestedIndex={index}
                  control={control}
                  register={register}
                  setValue={setValue}
                  setInitialState={setInitialState}
                  managePermission={managePermission}
                />
              </Box>
            ))}
          </Stack>
        </Box>
      ) : (
        <Typography sx={[textCenter, mt35]}>No GPA scales.</Typography>
      )}

      {managePermission && (
        <SaveChangesPopper
          open={saveChangesOpen}
          setOpen={setSaveChangesOpen}
          handleCancel={() => setInitialState(true)}
          handleSave={handleSubmit(onSubmit)}
        />
      )}
    </Authorize>
  );
}
