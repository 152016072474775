import React from "react";
import WarningIcon from "@mui/icons-material/Warning";
import { Tooltip } from "@mui/material";

export default function displayGradeLevel(gradeLevel) {
  if (gradeLevel === "null") {
    return (
      <Tooltip
        title="Warning: Student grade level has not been set."
        placement="left-start"
        componentsProps={{
          tooltip: {
            sx: {
              bgcolor: "#ed6c02",
              color: "white",
            },
          },
          arrow: {
            sx: {
              color: "#ed6c02",
            },
          },
        }}
        arrow
      >
        <WarningIcon color="warning" />
      </Tooltip>
    );
  }
  return gradeLevel;
}
