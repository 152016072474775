import {
  Box,
  Button,
  ButtonBase,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { updateEmail } from "../../../../service/guardiansService";
import SnackBarNotification from "../../../SnackBarNotification";
import usersService from "../../../../service/usersService";

export default function GuardianStudentInfo({
  guardian,
  setGuardianId,
  setIsGuardianDrawerOpen,
  hasPortalAccess,
}) {
  const [hovering, setHovering] = useState(false);
  const [editingEmail, setEditingEmail] = useState(false);
  const [snackBarOpen, setSnackBarOpen] = useState({
    open: false,
    message: null,
  });
  const [email, setEmail] = useState(guardian.email);

  const handleEmail = async (guardianId) => {
    const response = await updateEmail(guardianId, {
      email,
    });

    if (response.data) {
      setSnackBarOpen({
        open: true,
        message: "Email updated successfully",
      });
    } else {
      setSnackBarOpen({
        open: true,
        message: "Email could not be updated",
      });
    }
    setEditingEmail(false);
    setHovering(false);
  };

  const handleResetPassword = async () => {
    try {
      const response = await usersService.sendResetPasswordInstructions({
        email,
      });

      if (response) {
        setSnackBarOpen({
          open: true,
          message: "Reset password email sent successfully",
        });
      }
    } catch (e) {
      setSnackBarOpen({
        open: true,
        message: "Reset password email could not be sent",
        severity: "error",
      });
    }
  };

  return (
    <>
      <Grid
        item
        xs={12}
        container
        mt={4}
        mx={3}
        columnSpacing={2}
        key={guardian.id}
      >
        <Grid item flexDirection="column" rowGap={0.5}>
          <ButtonBase
            onClick={() => {
              setIsGuardianDrawerOpen(true);
              setGuardianId(guardian.id);
            }}
            sx={{ justifyContent: "flex-start" }}
          >
            <Typography>{`${guardian.first_name} ${guardian.last_name}`}</Typography>
          </ButtonBase>
          <Typography>
            {guardian.mailing_address?.address_line1}
            {guardian.mailing_address?.address_line1 && ", "}
            {guardian.mailing_address?.address_line2}
            {guardian.mailing_address?.address_line2 && ", "}
            {guardian.mailing_address?.city}
            {guardian.mailing_address?.city && ", "}
            {guardian.mailing_address?.state?.name}
            {guardian.mailing_address?.state && ", "}
            {guardian.mailing_address?.postal_code}
            {guardian.mailing_address?.postal_code && ", "}
            {guardian.mailing_address?.country?.name}
          </Typography>
          <Box
            onMouseEnter={() => setHovering(true)}
            onMouseLeave={() => setHovering(false)}
            display="flex"
            alignItems="center"
          >
            {editingEmail ? (
              <>
                <TextField
                  defaultValue={guardian.email}
                  variant="standard"
                  fullWidth
                  onChange={(e) => setEmail(e.target.value)}
                  value={email}
                />
                <Button
                  variant="text"
                  sx={{
                    color: "#2196F3",
                    textTransform: "none",
                    fontSize: "15px",
                  }}
                  onClick={() => handleEmail(guardian.id)}
                >
                  Save
                </Button>
                <Button
                  variant="text"
                  sx={{
                    color: "#2196F3",
                    textTransform: "none",
                    fontSize: "15px",
                  }}
                  onClick={() => setEditingEmail(false)}
                >
                  Cancel
                </Button>
              </>
            ) : (
              <>
                <Typography>{email}</Typography>
                <Button
                  variant="text"
                  sx={{
                    ml: 1,
                    opacity: hovering ? 1 : 0,
                    transition: "0.3s",
                    color: "#2196F3",
                    textTransform: "none",
                    fontSize: "15px",
                  }}
                  onClick={() => setEditingEmail(true)}
                >
                  Edit
                </Button>
              </>
            )}
          </Box>
          <Typography>
            Text (preferred number): {guardian.cell_phone}
          </Typography>
          <Typography>
            Account Status: {hasPortalAccess ? "Active" : "Inactive"}
          </Typography>
          <Typography>Language: {guardian.preferred_language}</Typography>
          {email && (
            <Button
              variant="text"
              sx={{
                color: "#2196F3",
                textTransform: "none",
                fontSize: "15px",
                p: 0,
                "&.MuiButtonBase-root:hover": {
                  bgcolor: "transparent",
                },
              }}
              onClick={() => handleResetPassword()}
            >
              Send reset password email
            </Button>
          )}
        </Grid>
      </Grid>
      <SnackBarNotification
        message={snackBarOpen.message}
        open={snackBarOpen.open}
        handleClose={() => setSnackBarOpen({ open: false })}
      />
    </>
  );
}
